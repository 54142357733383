import { LocationsDto } from "../../../clients/controller/OperationalContextClient";
import { VehicleStatusModel } from "../../model";

export enum VehicleStatusActionTypes {
    UPDATE_VEHICLE_STATUS = 'UPDATE_VEHICLE_STATUS',
    UPDATE_LOCATIONS = 'UPDATE_LOCATIONS',
    UPDATE_CLOSEST_VEHICLE = 'UPDATE_CLOSEST_VEHICLE'
}

export interface UpdateVehicleStatusAction {
    type: VehicleStatusActionTypes.UPDATE_VEHICLE_STATUS;
    payload: VehicleStatusModel;
}

export interface UpdateLocationsAction {
    type: VehicleStatusActionTypes.UPDATE_LOCATIONS;
    payload: LocationsDto[];
}

export interface UpdateClosestVehicleAction {
    type: VehicleStatusActionTypes.UPDATE_CLOSEST_VEHICLE;
    payload: LocationsDto;
}

export type VehicleStatusActions = UpdateLocationsAction | UpdateClosestVehicleAction | UpdateVehicleStatusAction;

export const actionCreators = {
    updateVehicleStatus: (status: VehicleStatusModel): UpdateVehicleStatusAction => ({
        type: VehicleStatusActionTypes.UPDATE_VEHICLE_STATUS,
        payload: status
    }),
    updateLocations: (locations: LocationsDto[]): UpdateLocationsAction => ({
        type: VehicleStatusActionTypes.UPDATE_LOCATIONS,
        payload: locations
    }),
    updateClosestVehicle: (closestVehicle: LocationsDto): UpdateClosestVehicleAction => ({
        type: VehicleStatusActionTypes.UPDATE_CLOSEST_VEHICLE,
        payload: closestVehicle
    })
};