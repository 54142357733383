import produce from "immer";
import { Reducer } from "redux";
import { MqttClient } from "../../../clients/controller/MqttClient";
import { MqttClientActions, MqttClientActionTypes } from "./actions";
export * from './actions';

export const INITIAL_STATE: MqttClient = null;

export const reducer: Reducer<MqttClient> =
    (state: MqttClient = INITIAL_STATE, action: MqttClientActions): MqttClient => {
        if (action.type === MqttClientActionTypes.SET_MQTT_CLIENT) {
            return setClient(state, action.payload);
        }
        return state;
    };

function setClient(state: MqttClient, payload: MqttClient): MqttClient {
    return payload;
}
