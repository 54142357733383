// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".leaflet-draw-tooltip{color:#000;font-weight:bold}.leaflet-map-pane>.leaflet-marker-pane>.leaflet-editing-icon:nth-of-type(2){background:gray}.leaflet-div-icon{background:transparent;border:none}.Map__layersContainer___pTA1t{position:absolute;height:100%;width:100%}.Map__mainArea___Vh8Bs{position:absolute;height:100%;width:100%}.Map__user-message___Bsrte{position:absolute;left:16px;bottom:16px;padding:2px 4px;border-radius:4px;z-index:1000;background:#757575}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floatingWindowPadding": "10px",
	"floatingWindowTitleHeight": "28px",
	"layersContainer": "Map__layersContainer___pTA1t",
	"mainArea": "Map__mainArea___Vh8Bs",
	"userMessage": "Map__user-message___Bsrte"
};
module.exports = ___CSS_LOADER_EXPORT___;
