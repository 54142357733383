import produce from "immer";
import { Reducer } from "redux";
import { AppConfig } from "../../model";
import { AppConfigurationAction, AppConfigurationActionTypes } from "./actions";
export * from './actions';

export const INITIAL_STATE: AppConfig = {
    serverAddress: "",
    navIconExtension: "",
    mqttHost: "",
    mqttPort: 0
};

export const reducer: Reducer<AppConfig> =
    (state: AppConfig = INITIAL_STATE, action: AppConfigurationAction): AppConfig => {
        if (action.type === AppConfigurationActionTypes.SET_APP_CONFIGURATION) {
            return setConfiguration(state, action.payload);
        }
        return state;
    };

function setConfiguration(state: AppConfig, payload: AppConfig): AppConfig {
    return produce(state, draft => {
        draft.serverAddress = payload.serverAddress,
        draft.navIconExtension = payload.navIconExtension,
        draft.mqttHost = payload.mqttHost,
        draft.mqttPort = payload.mqttPort
    });
}
