import { MqttClient } from "../../../clients/controller/MqttClient";

export enum MqttClientActionTypes {
    SET_MQTT_CLIENT = 'SET_MQTT_CLIENT',
}

export interface SetClientAction {
    type: MqttClientActionTypes;
    payload: MqttClient;
}

export type MqttClientActions = SetClientAction;

// tslint:disable-next-line: typedef
export const actionCreators = {
    setClient: (client: MqttClient): SetClientAction => ({
        type: MqttClientActionTypes.SET_MQTT_CLIENT,
        payload: client
    })
}