import * as React from 'react';

import styles from './TabularInformation.scss';
import classNames from 'classnames';

export interface TabularInformationCellCommonProps {
    cellMaxWidth?: string;
}

interface TabularInformationCellProps extends TabularInformationCellCommonProps {
    className?: string;
}

export class TabularInformationCell extends React.Component<TabularInformationCellProps> {
    static defaultProps: Pick<TabularInformationCellProps, 'className' | 'cellMaxWidth'> = { className: '', cellMaxWidth: 'auto' };

    render(): JSX.Element {
        return (
            <div style={{ maxWidth: this.props.cellMaxWidth }} className={classNames(styles.tableCell, this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}
