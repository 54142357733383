import { defaultTheme, ThemeOptions } from '@its-suite/form-controls';

import * as styles from '../styles/_themeOverrideVariables.scss';

const toolbarMinHeight: number = 49;
const tableRowHeight: number = 24;
const tableHeaderHeight: number = tableRowHeight * 1.5;
// tslint:disable-next-line:no-magic-numbers - it's the half
const fontSize: number = tableRowHeight / 2;
const svgIconSize: number = tableRowHeight * 0.75;
const inputFontSize: number = 14;
const inputVerticalPadding: number = 3;

export class AppTheme {
    // tslint:disable-next-line:no-magic-numbers - it's the double
    public static readonly TABLE_SELECTION_COLUMN_WIDTH: number = svgIconSize * 2;

    public static readonly THEME_OPTIONS: ThemeOptions = {
        typography: {
            ...defaultTheme.typography,
            body1: {
                // tslint:disable-next-line: no-string-literal - cannot be done in a different way
                ...defaultTheme.typography['body1'],
                fontSize: 14,
            },
            body2: {
                // tslint:disable-next-line: no-string-literal - cannot be done in a different way
                ...defaultTheme.typography['body2'],
                fontSize: 12,
            }
        },
        palette: defaultTheme.palette,
        overrides: {
            // Keep alphabetic order please!!
            ...defaultTheme.overrides,
            MuiCard: {
                ...defaultTheme.overrides.MuiCard,
                root: {
                    backgroundColor: styles.cardBgColor
                }
            },
            MuiCheckbox: {
                ...defaultTheme.overrides.MuiCheckbox,
                root: {
                    ...defaultTheme.overrides.MuiCheckbox.root,
                    height: tableRowHeight,
                    width: tableRowHeight,
                    // tslint:disable-next-line: no-duplicate-string - cannot be defined in const or variable
                    '&:last-child': {
                        padding: '2px 0px'
                    }
                }
            },
            MuiChip: {
                ...defaultTheme.overrides.MuiChip,
                root: {
                    ...defaultTheme.overrides.MuiChip.root,
                    fontSize: `${fontSize}px`,
                    height: `${tableRowHeight}px`,
                    '&:last-child': {
                        paddingRight: '0px'
                    }
                }
            },
            MuiExpansionPanelDetails: {
                root: {
                    padding: '8px'
                }
            },
            MuiExpansionPanelSummary: {
                root: {
                    minHeight: '48px',
                    '&$expanded': {
                        minHeight: '48px'
                    }
                },
                content: {
                    textTransform: 'uppercase',
                    '&$expanded': {
                        margin: '8px 0px'
                    }
                }
            },
            MuiIconButton: {
                ...defaultTheme.overrides.MuiIconButton,
                root: {
                    ...defaultTheme.overrides.MuiIconButton.root,
                    padding: '6px'
                }
            },
            MuiInputBase: {
                input: {
                    fontSize: `${inputFontSize}px`,
                    paddingTop: `${inputVerticalPadding}px`,
                    paddingBottom: `${inputVerticalPadding}px`
                }
            },
            MuiList: {
                ...defaultTheme.overrides.MuiList,
                padding: {
                    paddingTop: '4px',
                    paddingBottom: '4px'
                }
            },
            MuiListItem: {
                ...defaultTheme.overrides.MuiListItem,
                button: {
                    ...defaultTheme.overrides.MuiListItem.button,
                    '&$selected': {
                        backgroundColor: styles.listBgColorSelected,
                        '&:hover': {
                            backgroundColor: styles.listBgColorHover
                        }
                    }
                },
                dense: {
                    paddingTop: '0px',
                    paddingBottom: '0px'
                },
                gutters: {
                    '@media (min-width: 600px)': {
                        paddingLeft: '12px',
                        paddingRight: '12px'
                    }
                }
            },
            MuiListItemIcon: {
                root: {
                    ...defaultTheme.overrides.MuiMenuItem.root,
                    minWidth: '36px'
                }
            },
            MuiMenuItem: {
                gutters: {
                    paddingLeft: '12px',
                    paddingRight: '12px'
                },
                root: {
                    ...defaultTheme.overrides.MuiMenuItem.root,
                    fontSize: '13px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    minHeight: '24px'
                }
            },
            MuiSvgIcon: {
                ...defaultTheme.overrides.MuiSvgIcon,
                root: {
                    fontSize: `${svgIconSize}px`
                }
            },
            MuiTableCell: {
                ...defaultTheme.overrides.MuiTableCell,
                root: {
                    ...defaultTheme.overrides.MuiTableCell.root,
                    padding: '0px 12px',
                    '&:last-child': {
                        paddingRight: '12px'
                    }
                },
                body: {
                    ...defaultTheme.overrides.MuiTableCell.body,
                    fontSize: `${fontSize}px`
                },
            },
            MuiTableRow: {
                ...defaultTheme.overrides.MuiTableRow,
                root: {
                    ...defaultTheme.overrides.MuiTableRow.root,
                    height: `${tableRowHeight}px`
                },
                head: {
                    ...defaultTheme.overrides.MuiTableRow.head,
                    height: `${tableHeaderHeight}px`
                }
            },
            MuiToolbar: {
                ...defaultTheme.overrides.MuiToolbar,
                regular: {
                    '@media (min-width: 600px)': {
                        minHeight: `${toolbarMinHeight}px`
                    },
                    minHeight: `${toolbarMinHeight}px`,
                }
            },
            // Keep alphabetic order when adding please!!
        }
    };
}
