import { SynopticDto } from "../../../clients/controller/OperationalContextClient";

export enum FilterSelectionActionTypes {
    SET_FILTER_SELECTION = 'SET_FILTER_SELECTION',
}

export interface SetFilterSelectionAction {
    type: FilterSelectionActionTypes;
    payload: SynopticDto;
}

export type FilterSelectionActions = SetFilterSelectionAction;

// tslint:disable-next-line: typedef
export const actionCreators = {
    setSelection: (payload: SynopticDto): SetFilterSelectionAction => ({
        type: FilterSelectionActionTypes.SET_FILTER_SELECTION,
        payload: payload
    })
};