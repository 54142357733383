import { call, takeLatest } from 'redux-saga/effects';
import { TopologyClient } from "../../clients/TopologyClient";
import { AppActions } from "../actions";

export default class LineSaga {
    public static * watcher() {
        yield takeLatest(AppActions.ActionTypes.UPDATE_ESTIMATION_MULTIPLIER, LineSaga.estimationMultiplierWorker);
    }

    private static * estimationMultiplierWorker(action: AppActions.UpdateEstimationAction) {
        console.log('LineSaga: SendEstimationMultiplier');

        let topologyClient = new TopologyClient();
    }
}