// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContextMenu__MuiListItemInsideMenu___NV_8h{padding-top:3px;padding-bottom:3px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rtContextMenuIndentPadding": "48px",
	"muiListItemInsideMenu": "ContextMenu__MuiListItemInsideMenu___NV_8h"
};
module.exports = ___CSS_LOADER_EXPORT___;
