import { Reducer } from 'redux';
import produce from 'immer';

import { LinePanelSelectionModel } from '../../model';
import { Action, LinePanelSelectionActionTypes, LinePanelSelectionDictionary } from './actions';
import { exhaustiveCheck } from '@its-suite/app-commons';
export * from './actions';

const INITIAL_STATE: LinePanelSelectionDictionary = {};

export const reducer: Reducer<LinePanelSelectionDictionary> = (state: LinePanelSelectionDictionary = INITIAL_STATE, action: Action): LinePanelSelectionDictionary => {
    switch (action.type) {
        case LinePanelSelectionActionTypes.AddToLinePanelSelection:
            return addElementsToSelection(state, action.payload);
        case LinePanelSelectionActionTypes.ChangeLinePanelSelection:
            return changeSelection(state, action.payload);
        case LinePanelSelectionActionTypes.DeleteLinePanelSelection:
            return deletePanel(state, action.payload);
        case LinePanelSelectionActionTypes.ClearLinePanelSelection:
            return INITIAL_STATE;
        default:
            exhaustiveCheck(action);
    }
    return state;
};

/** Completely replaces a line panel selection */
function changeSelection(state: LinePanelSelectionDictionary, newPanelSelection: LinePanelSelectionModel): LinePanelSelectionDictionary {
    return produce(state, draft => {
        draft[newPanelSelection.panelId] = newPanelSelection;
    });
}

function deletePanel(state: LinePanelSelectionDictionary, panelId: string): LinePanelSelectionDictionary {
    return produce(state, draft => {
        if (draft.hasOwnProperty(panelId)) {
            delete draft[panelId];
        }
    });
}

/** Just adds elements to an existing line panel selection. If it doesn't exist, it creates a new line panel selection */
function addElementsToSelection(state: LinePanelSelectionDictionary, newPanelSelection: LinePanelSelectionModel): LinePanelSelectionDictionary {

    return produce(state, draft => {
        if (!draft.hasOwnProperty(newPanelSelection.panelId)) {
            draft[newPanelSelection.panelId] = newPanelSelection;
        } else {
            let updatedMapSelection: LinePanelSelectionModel = draft[newPanelSelection.panelId];
            addLines(updatedMapSelection);
        }
    });

    function addLines(updatedMapSelection: LinePanelSelectionModel): void {
        newPanelSelection.lines.forEach(newLineId => {
            if (updatedMapSelection.lines.indexOf(newLineId) === -1) {
                updatedMapSelection.lines.push(newLineId);
            }
        });
    }
}
