import { MessagesActions } from "@its-suite/app-commons";
import React from "react";

interface NotificationProps {
    showSnackbar(message: string): MessagesActions.TriggerShowSnackbarErrorMessageAction;
}

export class NotificationAlert extends React.Component<NotificationProps> {
    private audio = new Audio(require("../../assets/sound.mp3"));
    
    constructor(props) {
        super(props);
        this.showNotification = this.showNotification.bind(this);
      }

    componentDidMount() {
        Notification.requestPermission(result => {
            if (result === 'granted') {
              navigator.serviceWorker.ready.then(function(registration) {
                registration.showNotification('Notification with ServiceWorker');
              });
            }
          });

    }
    
    showNotification() {
        navigator.serviceWorker.getRegistrations().then(this.onFulfill, this.onReject).catch(this.props.showSnackbar );
        this.audio.play()
            .then(() => console.log("Sound"))
            .catch(error => console.log(error));
    }

    onFulfill(registrations) {
        registrations[0].showNotification("Service worker", {vibrate: [200, 100, 200, 100, 200, 100, 200]});
        this.props.showSnackbar("Fulfilled");
    }

    onReject(reason) {
        console.log(reason);
        this.props.showSnackbar(reason);
    }

    render() {
        return (        
        <button onClick={this.showNotification}>
            Notification test
        </button>);
    }
}