// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainArea__mainArea___PRVfd{position:fixed;height:calc(100% - 50px);width:100%;background:#424242;display:flex;flex-flow:column}.MainArea__mapContainer___couDa{flex-grow:1;position:relative}.MainArea__detailContainer___TBq26{height:fit-content}.MainArea__fabButton___UryzN{position:absolute;margin-top:-28px;right:5%;z-index:1250}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floatingWindowPadding": "10px",
	"floatingWindowTitleHeight": "28px",
	"mainArea": "MainArea__mainArea___PRVfd",
	"mapContainer": "MainArea__mapContainer___couDa",
	"detailContainer": "MainArea__detailContainer___TBq26",
	"fabButton": "MainArea__fabButton___UryzN"
};
module.exports = ___CSS_LOADER_EXPORT___;
