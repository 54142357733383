import TrainIcon from '@material-ui/icons/Train';
import L from "leaflet";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { connect } from "react-redux";
import { LocationsDto, SynopticDto } from "../../../../clients/controller/OperationalContextClient";
import { AppState } from "../../../../redux";

interface LocationsLayerProps {
    map: L.Map;
    currentLocation: L.Marker;
}

interface LocationsLayerStoreProps {
    locations: LocationsDto[];
    filter: SynopticDto;
}

export type LocationsLayerAllProps = LocationsLayerProps & LocationsLayerStoreProps;

class LocationsLayer extends React.Component<LocationsLayerAllProps> {
    private markers: L.Marker[] = [];

    constructor(props: LocationsLayerAllProps) {
        super(props);

        this.state = {
            locations: []
        }

        if (this.props.map !== undefined) this.drawLocations();
    }

    private drawLocations(): void {
        this.markers.forEach(marker => marker.remove());
        this.markers = [];
        this.props.locations.filter(location => this.props.filter.id !== -1 ? this.props.filter.estaciones.includes(location.lastId) : true)
            .forEach(location => this.addLocationMarker(location));
    }

    private addLocationMarker(location: LocationsDto): void {

        let icon: L.DivIcon = L.divIcon({
            html: renderToStaticMarkup(<TrainIcon fontSize="large" htmlColor='#000000'/>),
            iconSize: [50, 50]
        });

        let marker: L.Marker = new L.Marker({lat: location.lat, lng: location.lon}).bindPopup(L.popup().setContent(`${location.nt}`));
        marker.setIcon(icon);

        marker.addTo(this.props.map);
        this.markers.push(marker);
    }

    componentDidUpdate(prevProps: LocationsLayerProps): void {
        if (this.props.map === undefined) return;
        if (prevProps !== this.props)
            this.drawLocations();
    }

    render(): React.ReactNode {
        return(<></>);
    }
}

function mapStateToProps(state: AppState) {
    return {
        locations: state.vehicleStatus.locations,
        filter: state.filterSelection
    }
}

export default connect<LocationsLayerStoreProps>(mapStateToProps)(LocationsLayer);