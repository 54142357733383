import { FormSubmit, TextField, Typography, ValidatorForm } from "@its-suite/form-controls";
import { PasswordField } from "@its-suite/operational-context-commons";
import React from "react";
import styles from "./LoginFormWindow.scss";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import { LanguageModel } from "../../redux";

interface LoginFormProps {
    language: LanguageModel;
    onSave(user: string, password: string): void;
}

interface LoginFormState {
    userFieldValue: string;
    passFieldValue: string;
}

export class LoginForm extends React.Component<LoginFormProps, LoginFormState> {

    constructor(props: LoginFormProps) {
        super(props);

        this.onSave = this.onSave.bind(this);

        this.state = {
            userFieldValue: '',
            passFieldValue: ''
        }
    }

    handleUserTextFieldChange = (e: any): void => {
        this.setState({ userFieldValue: e.target.value });
    }

    handlePassTextFieldChange = (e: any): void => {
        this.setState({ passFieldValue: e.target.value });
    }

    onSave() {
        this.props.onSave(this.state.userFieldValue, this.state.passFieldValue);
    }

    render() {
        return (
            <div className={styles.formControlsComponentGroupContainer}>
                <ValidatorForm className={`${styles.form} ${styles.formMaxWidth}`} onSubmit={this.onSave} id='numberInput'>
                    <Typography className={styles.formTitle} variant='subtitle1' gutterBottom={true}>
                        {this.props.language["loginFormTitle"]}
                    </Typography>
                    <div className={styles.inputContainer}>
                        <MailOutlineIcon fontSize="large" className={styles.image}/>
                        <TextField
                            name={'user'}
                            label={this.props.language["loginFormUser"]}
                            value={this.state.userFieldValue}
                            autoComplete='on'
                            id={'demo-tf'}
                            onChange={this.handleUserTextFieldChange}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <LockIcon fontSize="large" htmlColor="#FFFFFF" className={styles.image}/>
                        <PasswordField
                            name={'password'}
                            prefixId={'demo'}
                            label={this.props.language["loginFormPass"]}
                            value={this.state.passFieldValue}
                            autoComplete='off'
                            onChange={this.handlePassTextFieldChange}
                        />
                    </div>
                    <div className={styles.buttonContainer}>
                        <button type="submit" className={styles.submitButton}>
                            {this.props.language["loginFormSubmit"]}
                        </button>
                    </div>
                </ValidatorForm>
            </div>
        );
    }
}