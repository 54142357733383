import React from "react";
import { connect } from "react-redux";
import { LoginFormWindow } from "../../components/LoginWindow";
import { AppState, LanguageModel } from "../../redux";
import { Redirect } from 'react-router';
import { ApplicationRoutes, MessagesActions, SnackbarWrapper } from "@its-suite/app-commons";
import { bindActionCreators, Dispatch } from "redux";
import { AppActions } from "../../redux/actions";
import { LoginClient } from "../../clients/LoginClient";
import { JwtManager } from "../../clients/jwt";
import { SessionModule } from "@its-suite/operational-context-commons";
import { RealTimePermissions } from "../../authorization";

interface LoginContainerStoreProps {
    baseUrl: string;
    language: LanguageModel;
}

interface LoginContainerActionProps {
    setSessionPermissions(permission: any): SessionModule.SessionAction;
    showSnackbar(message: string): MessagesActions.TriggerShowSnackbarErrorMessageAction;
    triggerUpdateDataSaga(): AppActions.TriggerUpdateDataSagaAction;
}

type LoginContainerAllProps = LoginContainerStoreProps & LoginContainerActionProps;

interface LoginFormWindowState {
    logged: boolean;
}

class LoginContainer extends React.Component<LoginContainerAllProps, LoginFormWindowState> {

    constructor(props: LoginContainerAllProps) {
        super(props);

        this.state = {
            logged: false
        }

        this.onSave = this.onSave.bind(this);
    }

    onSave() {
        this.props.triggerUpdateDataSaga();
        this.setState(_ => ({ logged: true }))
    }
    
    componentDidMount(): void {
        new LoginClient().renewToken(new JwtManager().getRenewToken())
            .then(tokens => {
                if (tokens.accessToken) this.setState(_ => ({ logged: true })); 
            }).catch(e => console.log("Error getting session, returning to login"));
    }

    render() {
        if (this.state.logged) {
            this.props.setSessionPermissions({ permissions: RealTimePermissions.realtimeApp })
            return <Redirect to={ApplicationRoutes.ROOT} push={true}/>
        } 
        return (
            <SnackbarWrapper>
                <LoginFormWindow id={"formComponent"} language={this.props.language} baseUrl={this.props.baseUrl} onSave={this.onSave} onLoginError={() => 
                    this.props.showSnackbar(this.props.language["loginError"])}/>
            </SnackbarWrapper>
        );
    };
}

function mapStateToProps(state: AppState): LoginContainerStoreProps {
    return {
        baseUrl: state.configuration.BaseUrl,
        language: state.language
    };
}

function mapDispatchToProps(dispatch: Dispatch): LoginContainerActionProps {
    return bindActionCreators({
        setSessionPermissions: SessionModule.actionCreators.setSession,
        showSnackbar: MessagesActions.actionCreators.triggerShowErrorMessage,
        triggerUpdateDataSaga: AppActions.actionCreators.triggerUpdateDataSaga
    }, dispatch);
}

export default connect<LoginContainerStoreProps, LoginContainerActionProps>(mapStateToProps, mapDispatchToProps)(LoginContainer)