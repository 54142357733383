import "font-awesome/css/font-awesome.min.css";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton/src/easy-button.js";
import 'leaflet/dist/leaflet.css';
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { LocationsDto } from "../../clients/controller/OperationalContextClient";
import { AppState, VehicleStatusModule } from "../../redux";
import locationIcon from '../../res/img/locationMarker/currentLocation.png';
import { LocationsLayer, TopologyLayer } from "./Layers";
import styles from './Map.scss';

interface MapProps {
    id: string;
}

interface MapStoreProps {
    locations: LocationsDto[];
}

interface MapStoreActionProps {
    updateLocations(locations: LocationsDto[]): VehicleStatusModule.VehicleStatusActions;
}

interface MapState {
    map: L.Map;
    currentLocation: L.Marker;
}

type MapAllProps = MapProps & MapStoreProps & MapStoreActionProps;

class MapContainer extends React.Component<MapAllProps, MapState> {
    private readonly defaultMapZoom: number = 13;
    private readonly maxBounds: L.LatLngBoundsExpression = [[-90, -180], [90, 180]]
    private defaultMapCenter: L.LatLngTuple = [41.514648, -4.7174];
    private watchId: number;

    constructor(props: MapAllProps) {
        super(props);

        this.flyToLocation = this.flyToLocation.bind(this);
        this.setNewLocation = this.setNewLocation.bind(this);
        this.calculateDistances = this.calculateDistances.bind(this);

        this.state = {
            map: undefined,
            currentLocation: undefined,
        }
    }

    componentDidMount(): void {
        let mapLayer: L.TileLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
        let showAttribution: boolean = false;

        let map = L.map(
            this.props.id,
            {
                minZoom: 3,
                maxZoom: 18,
                maxBounds: this.maxBounds,
                maxBoundsViscosity: 0.3,
                layers: [mapLayer],
                attributionControl: showAttribution,
            });

        let location = this.state.currentLocation ? this.state.currentLocation.getLatLng() : undefined;
        let center: L.LatLngTuple = location ? [location.lat, location.lng] : this.defaultMapCenter;
        let zoom: number = this.defaultMapZoom;
        map.setView(center, zoom);

        let url: string = locationIcon;
        let icon: L.Icon = L.icon({
            iconUrl: url,
            iconSize: new L.Point(50, 50)
        });

        let currentLocation = new L.Marker({lat: 0, lng: 0}).setIcon(icon);

        L.easyButton("fa-crosshairs", this.flyToLocation).addTo(map);

        let temporalDrawingLayer: L.FeatureGroup = new L.FeatureGroup();
        map.addLayer(temporalDrawingLayer);
        
        this.watchId = navigator.geolocation.watchPosition(this.setNewLocation, this.errorObtainingLocation, { enableHighAccuracy: true });      
        
        this.setState(_ => ({ 
            map: map,
            currentLocation: currentLocation
        }));
    }

    componentWillUnmount = (): void => {
        navigator.geolocation.clearWatch(this.watchId);
    }

    setNewLocation(position: GeolocationPosition): void {
        console.log('Updating location');
        let newLocation = new L.Marker(new L.LatLng(position.coords.latitude, position.coords.longitude))
            .setIcon(this.state.currentLocation.getIcon());

        this.defaultMapCenter = [newLocation.getLatLng().lat, newLocation.getLatLng().lng];
        this.state.map.setView(this.defaultMapCenter);
            
        this.state.currentLocation.remove();
        this.setState(state => ({currentLocation: newLocation.addTo(state.map)}));
        this.calculateDistances(newLocation.getLatLng());
    }

    errorObtainingLocation(error: any): void {
        console.log(error);
    }

    calculateDistances(newLocation: L.LatLng): void {
        let newLocations = [];
        this.props.locations.forEach(location => {
            let locationCopy = {...location};
            locationCopy.distance = newLocation.distanceTo(new L.LatLng(location.lat, location.lon));
            newLocations.push(locationCopy);
        });
        this.props.updateLocations(newLocations);
    }

    flyToLocation = (): void => {
        let target: L.LatLng = this.state.currentLocation.getLatLng();
        if (target === undefined || (target.lat === 0 && target.lng === 0)) return;
        this.state.map.flyTo(target, 16);
    }

    render() {
        return (
            <div id={this.props.id} className={styles.layersContainer}>
                <LocationsLayer map={this.state.map} currentLocation={this.state.currentLocation} />
                <TopologyLayer map={this.state.map} />
            </div>
        );
    }
}

function mapStateToProps(state: AppState): MapStoreProps {
    return {
        locations: state.vehicleStatus.locations
    };
}

function mapDispatchToProps(dispatch: Dispatch): MapStoreActionProps {
    return bindActionCreators({
        updateLocations: VehicleStatusModule.actionCreators.updateLocations
    }, dispatch);
}

// tslint:disable-next-line:variable-name
export default connect<MapStoreProps, MapStoreActionProps>(mapStateToProps, mapDispatchToProps)(MapContainer);
