import { OperationalContextClient } from "./controller/OperationalContextClient";

export class LoginClient {
    private client: OperationalContextClient;

    constructor() {
        this.client = new OperationalContextClient();
    }

    login(login: string, password: string) {
        return this.client.apiLoginPost(login, password);
    }
    
    logout() {
        return this.client.apiLogoutGet();
    }

    renewToken(refreshToken: string) {
        return this.client.apiLoginRenewPost(refreshToken);
    }
}