import { Reducer } from 'redux';
import produce, { DraftArray } from 'immer';
import { MapSelectionActionTypes, Action, MapSelectionDictionary } from './actions';
import { exhaustiveCheck } from '@its-suite/app-commons';
import { PositionDto } from '../../../clients/controller/OperationalContextClient';
import { MapSelectionModel } from '../../model/MapSelectionModel';
export * from './actions';

const INITIAL_STATE: MapSelectionDictionary = {};

export const reducer: Reducer<MapSelectionDictionary> = (state: MapSelectionDictionary = INITIAL_STATE, action: Action): MapSelectionDictionary => {
    switch (action.type) {
        case MapSelectionActionTypes.CreateMapSelection:
            return createMapSelection(state, action.payload);
        case MapSelectionActionTypes.AddToMapSelection:
            return addElementsToSelection(state, action.payload);
        case MapSelectionActionTypes.ChangeMapSelection:
            return changeSelection(state, action.payload);
        case MapSelectionActionTypes.DeleteMapSelection:
            return deleteMap(state, action.payload);
        case MapSelectionActionTypes.TrackVehicleInMap:
            return trackVehicle(state, action.payload.mapId, action.payload.vehicleAppId);
        case MapSelectionActionTypes.StopTrackVehicleInMap:
            return stopTrackingVehicle(state, action.payload);
        case MapSelectionActionTypes.CenterMapInVehicle:
            return centerMapInVehicle(state, action.payload.mapId, action.payload.center, action.payload.vehicleAppId);
        case MapSelectionActionTypes.ClearMapSelection:
            return INITIAL_STATE;
        default:
            exhaustiveCheck(action);
    }
    return state;
};

function createMapSelection(state: MapSelectionDictionary, payload: string): MapSelectionDictionary {
    let newMapSelection: MapSelectionModel = {
        mapId: payload,
        lines: [],
        routes: [],
        stopPoints: [],
        timingPoints: [],
        vehicles: [],
        zones: []
    };
    return produce(state, draft => {
        draft[newMapSelection.mapId] = newMapSelection;
    });
}

/** Completely replaces a map selection */
function changeSelection(state: MapSelectionDictionary, newMapSelection: MapSelectionModel): MapSelectionDictionary {
    return produce(state, draft => {
        draft[newMapSelection.mapId] = newMapSelection;
    });
}

function deleteMap(state: MapSelectionDictionary, mapId: string): MapSelectionDictionary {
    return produce(state, draft => {
        if (draft.hasOwnProperty(mapId)) {
            delete draft[mapId];
        }
    });
}

/** Just adds elements to an existing map selection. If it doesn't exist, it creates a new map selection */
function addElementsToSelection(state: MapSelectionDictionary, newMapSelection: MapSelectionModel): MapSelectionDictionary {
    const updatedState: MapSelectionDictionary = produce(state, draft => {
        if (!draft.hasOwnProperty(newMapSelection.mapId)) {
            draft[newMapSelection.mapId] = newMapSelection;
        } else {
            let updatedMapSelection: MapSelectionModel = draft[newMapSelection.mapId];
            addLines(updatedMapSelection);
            addRoutes(updatedMapSelection);
            addVehicles(updatedMapSelection);
            addStopPoints(updatedMapSelection);
            addTimingPoints(updatedMapSelection);
            addZones(updatedMapSelection);
        }
    });

    return updatedState;

    function addTimingPoints(updatedMapSelection: MapSelectionModel): void {
        newMapSelection.timingPoints.forEach(newTimingPointId => {
            if (updatedMapSelection.timingPoints.indexOf(newTimingPointId) === -1) {
                updatedMapSelection.timingPoints.push(newTimingPointId);
            }
        });
    }

    function addStopPoints(updatedMapSelection: MapSelectionModel): void {
        newMapSelection.stopPoints.forEach(newStopPointId => {
            if (updatedMapSelection.stopPoints.indexOf(newStopPointId) === -1) {
                updatedMapSelection.stopPoints.push(newStopPointId);
            }
        });
    }

    function addVehicles(updatedMapSelection: MapSelectionModel): void {
        newMapSelection.vehicles.forEach(newVehicleId => {
            if (updatedMapSelection.vehicles.indexOf(newVehicleId) === -1) {
                updatedMapSelection.vehicles.push(newVehicleId);
            }
        });
    }

    function addRoutes(updatedMapSelection: MapSelectionModel): void {
        newMapSelection.routes.forEach(newRouteId => {
            if (updatedMapSelection.routes.indexOf(newRouteId) === -1) {
                updatedMapSelection.routes.push(newRouteId);
            }
        });
    }

    function addLines(updatedMapSelection: MapSelectionModel): void {
        newMapSelection.lines.forEach(newLineId => {
            if (updatedMapSelection.lines.indexOf(newLineId) === -1) {
                updatedMapSelection.lines.push(newLineId);
            }
        });
    }

    function addZones(updatedMapSelection: MapSelectionModel): void {
        newMapSelection.zones.forEach(newZoneId => {
            if (updatedMapSelection.zones.indexOf(newZoneId) === -1) {
                updatedMapSelection.zones.push(newZoneId);
            }
        });
    }
}

function trackVehicle(state: MapSelectionDictionary, mapId: string, vehicleAppId: string): MapSelectionDictionary {
    const updatedState: MapSelectionDictionary = produce(state, draft => {
        draft[mapId].trackVehicleId = vehicleAppId;
        draft[mapId].vehicles.push(vehicleAppId);
    });
    return updatedState;
}

function stopTrackingVehicle(state: MapSelectionDictionary, vehicleAppId: string): MapSelectionDictionary {
    const updatedState: MapSelectionDictionary = produce(state, draft => {
        let mapsTrackingVehicle: MapSelectionModel[] = Object.values(state).filter(ms => ms.trackVehicleId === vehicleAppId);
        mapsTrackingVehicle.forEach(mtv => {
            draft[mtv.mapId].trackVehicleId = undefined;
            draft[mtv.mapId].vehicles = draft[mtv.mapId].vehicles.filter(vh => vh !== vehicleAppId);
        }
        );
    });
    return updatedState;
}

function centerMapInVehicle(state: MapSelectionDictionary, mapId: string, center: PositionDto, vehicleAppId: string): MapSelectionDictionary {
    const updatedState: MapSelectionDictionary = produce(state, draft => {
        ensureReferenceToSelectedVehicleIsUpdated(draft[mapId].vehicles, vehicleAppId);
        draft[mapId].vehicles.push(vehicleAppId);
        draft[mapId].center = center;
    });
    return updatedState;
}

/** The fact that we avoid adding a vehicle to the selection if it is already selected is not enough to be able to distinguish between
 * changes in map center and selected vehicle, and changes in map center only (e.g. the user moves the map)
 * By updating this reference, the Map container is able to execute different flows depending on the event.
 */
function ensureReferenceToSelectedVehicleIsUpdated(vehicles: DraftArray<string[]>, vehicleAppId: string): void {
    let positionInSelectedVehicles: number = vehicles.indexOf(vehicleAppId);
    if (positionInSelectedVehicles !== -1) {
        vehicles.splice(positionInSelectedVehicles, 1);
    }
}
