import { JwtStorage } from './JwtStorage';

export class JwtManager {
    storage: JwtStorage;
    constructor(storage?: JwtStorage) {
        this.storage = storage ? storage : new JwtStorage();
    }
    save(rawToken: string, renewToken: string): string {
        let payload = this.read(rawToken);
        this.storage.saveToken(rawToken);

        payload = this.read(renewToken);
        this.storage.saveRenewToken(renewToken);

        return payload;
    }
    getRawToken(): string {
        return this.storage.readToken();
    }
    getRenewToken(): string {
        return this.storage.readRenewToken();
    }
    forget(): void {
        this.storage.removeToken();
    }
    validateToken(): boolean {
        return this.storage.readToken() !== null;
    }
    private read(value) {
        try {
            return value;
        }
        catch (e) {
            console.error(`[${new Date()}] JwtManager.read() => Invalid token: ${value}`);
            console.error(`[${new Date()}] JwtManager.read() => Invalid token error: ${e}`);
            throw Error(`Invalid token ${value}`);
        }
    }
    private decode64(value) {
        return decodeURIComponent(escape(atob(value)));
    }
    private getArray(values) {
        if (!values) {
            return [];
        }
        else if (values instanceof Array) {
            return [...values];
        }
        else {
            return [values];
        }
    }
}
