// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloatingWindow__floatingWindow___zvdYO{background-color:#616161;color:rgba(255,255,255,.9);border:2px solid rgba(72,72,72,.5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floatingWindowPadding": "10px",
	"floatingWindowTitleHeight": "28px",
	"floatingWindow": "FloatingWindow__floatingWindow___zvdYO"
};
module.exports = ___CSS_LOADER_EXPORT___;
