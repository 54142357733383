﻿"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s)  {
		if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) {
			t[p] = s[p];
		}
	}
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Menu_1 = require("@material-ui/core/Menu");
const MenuItem_1 = require("@material-ui/core/MenuItem");
const ListItemIcon_1 = require("@material-ui/core/ListItemIcon");
const ListItemText_1 = require("@material-ui/core/ListItemText");
const Backdrop_1 = require("@material-ui/core/Backdrop");
const Divider_1 = require("@material-ui/core/Divider");
const MenuItemWithSubmenu_1 = require("./MenuItemWithSubmenu");
const styles = require("./DropdownMenu.scss");
class DropdownContextMenu extends React.Component {
    constructor() {
        super(...arguments);
        this.render = () => {
            let _a = this.props, { menuItems, preserveLeftIconWidth } = _a, rest = __rest(_a, ["menuItems", "preserveLeftIconWidth"]);
            return (React.createElement("div", null,
                React.createElement(Backdrop_1.default, { open: this.props.open, onClick: this.handleClose, invisible: true, key: `contextmenu-backdrop` }),
                React.createElement(Menu_1.default, Object.assign({}, rest), this.renderMenuItems(this.props.menuItems))));
        };
        this.renderMenuItems = (menuItems) => {
            return menuItems.map((menuItem, index) => this.renderMenuItem(menuItem, index));
        };
        this.renderMenuItem = (item, index) => {
            let element;
            if (item.kind === 'Item') {
                element = this.renderNestedMenuItem(item, index);
            }
            else if (item.kind === 'MenuDivider') {
                element = this.renderMenuDivider(item, index);
            }
            else if (item.kind === 'WithMenu') {
                element = this.renderItemWithMenu(item, index);
            }
            return element;
        };
        this.renderItemWithMenu = (item, index) => {
            let content = (React.createElement(MenuItemWithSubmenu_1.MenuItemWithSubmenu, { text: item.text, suboptions: item.children, icon: item.icon, onClose: this.props.onClose, preserveLeftIconWidth: this.props.preserveLeftIconWidth }));
            return (React.createElement("div", { key: `${item.key}-item-${index}` }, content));
        };
        this.renderNestedMenuItem = (item, index) => {
            let content = this.renderNestedMenuItemAsClickable(item, index);
            return (React.createElement("div", { key: `${item.key}-item-${index}` }, content));
        };
        this.renderNestedMenuItemAsClickable = (item, index) => {
            let handleClick = (event) => {
                this.handleClose(event);
                item.onClick(event);
            };
            return (React.createElement(MenuItem_1.default, { key: `${item.key}-menuitem-${index}`, disabled: item.disabled, onClick: handleClick, onTouchStart: handleClick, onTouchEnd: this.preventOnClickIfOnTouchWasTriggered }, this.renderNestedMenuItemContent(item, index)));
        };
        this.renderNestedMenuItemContent = (item, index) => {
            return (React.createElement(React.Fragment, { key: `${item.key}-menuitemcontent-${index}` },
                item.icon && React.createElement(ListItemIcon_1.default, { className: styles.menuItemIcon }, item.icon),
                React.createElement(ListItemText_1.default, null, item.text)));
        };
        this.renderMenuDivider = (item, index) => {
            return (React.createElement(Divider_1.default, { key: `${index}-divider` }));
        };
        this.handleClose = (event) => {
            event.stopPropagation();
            this.props.onClose();
        };
        /** Prevent firing onClick when onTouch was already fired (https://www.html5rocks.com/en/mobile/touchandmouse/#toc-together) */
        this.preventOnClickIfOnTouchWasTriggered = (event) => {
            event.preventDefault();
        };
    }
}
exports.DropdownContextMenu = DropdownContextMenu;
