import { AppStatusModule } from '@its-suite/app-commons';
import { all, call, put, takeLatest, PutEffect, select } from 'redux-saga/effects';
import { JwtManager } from '../../clients/jwt';
import { LoginClient } from '../../clients/LoginClient';
import { AppActions } from "../actions";

export default class RenewTokenSaga {
    public static * watcher() {
        yield takeLatest(AppActions.ActionTypes.TRIGGER_RENEW_TOKEN_SAGA, RenewTokenSaga.worker);
    }

    private static * worker(action: AppActions.TriggerRenewTokenSagaAction) {
        yield call(RenewTokenSaga.renewTokenSaga);
    }

    private static * renewTokenSaga() {
        let state = yield select();
        if (state.appStatus !== AppStatusModule.AppStatusModel.Ready) return;
        
        console.log('RenewTokenSaga');
        let jwtManager = new JwtManager();
        if (jwtManager.getRawToken()) {
            try {
                if (jwtManager.validateToken()) {
                    let loginClient = new LoginClient();
                    let token = yield call([loginClient, loginClient.renewToken], jwtManager.getRenewToken());
                    jwtManager.save(token.accessToken, token.refreshToken);
                }
            }
            catch (error) {
                yield call(RenewTokenSaga.onErrorUpdatingSession);
            }
        }
    }

    static * onErrorUpdatingSession() {
        console.error('RenewTokenSaga: Error updating session');
    }
}