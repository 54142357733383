import React from "react";
import { JwtManager } from "../../clients/jwt";
import { LoginClient } from "../../clients/LoginClient";
import { LanguageModel } from "../../redux";
import { LoginForm } from "./LoginForm";
import styles from './LoginFormWindow.scss';
import trainImage from '../../res/img/portada.png';
import logoImage from '../../res/img/logo.png';
interface LoginFormWindowProps {
    id: string;
    baseUrl: string;
    language: LanguageModel;
    onSave(): void;
    onLoginError(): void;
}

export class LoginFormWindow extends React.Component<LoginFormWindowProps> {

    constructor(props: LoginFormWindowProps) {
        super(props);

        this.onSave = this.onSave.bind(this);
    }

    private onSave(user: string, password: string) {
        new LoginClient().login(user, password)
        .then(tokens => {
            if (tokens.accessToken) {
                new JwtManager().save(tokens.accessToken, tokens.refreshToken);
                this.props.onSave();
            } else {
                console.log(`Error logging in`);
                this.props.onLoginError();
            }
        })
        .catch(error => {
            console.log(`Error: ${error}`);
            this.props.onLoginError();
        });
    }

    render(): JSX.Element {
        return (
            <div className={styles.windowContainer}>
                <img className={styles.imageContainer} src={trainImage}/>
                <img className={styles.imageContainer} src={logoImage}/>
                <div className={styles.surfaceContainer}>
                    <LoginForm language={this.props.language} onSave={this.onSave} />
                </div>
            </div>

        );
    }
}