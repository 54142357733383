import { Reducer } from 'redux';
import { WindowSelectionAction, WindowSelectionDictionary, WindowSelectionTypes } from './actions';
import { WindowSelectionModel } from '../../model';
import produce from 'immer';
export * from './actions';

const INITIAL_STATE: WindowSelectionDictionary = {};

export const reducer: Reducer<WindowSelectionDictionary> = (state: WindowSelectionDictionary = INITIAL_STATE, action: WindowSelectionAction): WindowSelectionDictionary => {
    switch (action.type) {
        case WindowSelectionTypes.SET_WINDOW_SELECTION:
            return setWindowSelection(state, action.payload);
        case WindowSelectionTypes.CLEAR_WINDOW_SELECTION:
            return setWindowSelection(state, action.payload);
        default:
            return state;
    }
};

function setWindowSelection(state: WindowSelectionDictionary, payload: WindowSelectionModel): WindowSelectionDictionary {
    return produce(state, draft => {
        draft[payload.windowId] = payload.selectedAppId
    });
}




