﻿"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const form_controls_1 = require("@its-suite/form-controls");
const styles = require("./ProductInfo.scss");
class ProductInfo extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: styles.productInfoContainer },
            React.createElement(form_controls_1.Typography, { className: styles.productInfoText, variant: 'body1' }, this.props.productName.toUpperCase()),
            React.createElement("img", { className: `${styles.icon}`, src: require('../../assets/navIcon' + (this.props.navIconExtension ? this.props.navIconExtension : "")) })
        ));
    }
}

exports.ProductInfo = ProductInfo;
ProductInfo.defaultProps = { productName: '' };