import * as React from 'react';
import FilterIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import { Tooltip } from '@its-suite/form-controls';

import styles from './FloatingWindowTitle.scss';

export interface FloatingWindowTitleText {
    filters: string;
    close: string;
}

interface FloatingWindowTitleProps {
    title: string;
    draggable?: boolean;
    additionalIcons?: JSX.Element[];
    onClose?(): void;
    onFilter?(): void;
    onDragStart?(event: React.DragEvent<HTMLDivElement>): void;
    onDrag?(event: React.DragEvent<HTMLDivElement>): void;
}

export class FloatingWindowTitle extends React.Component<FloatingWindowTitleProps> {

    render(): JSX.Element {
        let onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
        if (this.props.draggable && this.props.onDragStart) {
            onDragStart = this.props.onDragStart;
        }
        return (
            <div className={styles.title} draggable={this.props.draggable} onDragStart={this.props.onDragStart} onDrag={this.props.onDrag}>
                <span>{this.props.title.toUpperCase()}</span>
                <div className={styles.iconContainer}>
                    {this.renderAdditionalIcons()}
                    {this.props.onFilter && this.renderFilter()}
                    {this.props.onClose && this.renderCloseButton()}
                </div>
            </div>
        );
    }

    renderAdditionalIcons = (): JSX.Element => {
        let content: JSX.Element = null;
        if (this.props.additionalIcons?.length > 0) {
            content = <React.Fragment>{this.props.additionalIcons}</React.Fragment>;
        }
        return content;
    }

    renderFilter(): JSX.Element {
        return (
            <Tooltip title={'Title'}>
                <FilterIcon onClick={this.props.onFilter} />
            </Tooltip>
        );
    }

    renderCloseButton(): JSX.Element {
        return (
            <Tooltip title={'Close'}>
                <ClearIcon onClick={this.props.onClose} />
            </Tooltip>
        );
    }
}
