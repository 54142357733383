import * as React from 'react';
import { Typography } from '@its-suite/form-controls';
import { TabularInformationCell, TabularInformationCellCommonProps } from './TabularInformationCell';

import styles from './TabularInformation.scss';

export interface TabularInformationLabelProps extends TabularInformationCellCommonProps {
    translatedText: string;
}

export class TabularInformationLabel extends React.Component<TabularInformationLabelProps> {
    render(): JSX.Element {
        return (
            <TabularInformationCell {...this.props} >
                <Typography variant={'body2'} className={styles.infoLabel}>
                    {`${this.props.translatedText}:`}
                </Typography>
            </TabularInformationCell>
        );
    }
}
