import * as React from 'react';
import classNames from 'classnames';
import { FloatingWindowTitle } from '../FloatingWindowTitle';

import styles from './FloatingWindow.scss';

export interface DraggableWindowOptions {
    draggable: boolean;
    onDragStart(event: React.DragEvent<HTMLDivElement>): void;
    onDrag?(event: React.DragEvent<HTMLDivElement>): void;
}

export interface FloatingWindowProps {
    id: string;
    title: string;
    className?: string;
    style?: React.CSSProperties;
    draggableOptions?: DraggableWindowOptions;
    additionalIcons?: JSX.Element[];
    onClose(): void;
}

export class FloatingWindow extends React.Component<FloatingWindowProps> {
    static defaultProps: Pick<FloatingWindowProps, 'draggableOptions'> = {
        draggableOptions: { draggable: false, onDragStart: (): void => null, onDrag: (): void => null }
    };

    render(): JSX.Element {
        let className: string = styles.floatingWindow;
        if (this.props.className) {
            className = classNames(className, this.props.className);
        }
        return (
            <div className={className} style={this.props.style} id={this.props.id}>
                <FloatingWindowTitle
                    title={this.props.title}
                    onClose={this.props.onClose}
                    draggable={this.props.draggableOptions.draggable}
                    onDragStart={this.props.draggableOptions.onDragStart}
                    onDrag={this.props.draggableOptions.onDrag}
                    additionalIcons={this.props.additionalIcons}
                />
                {this.props.children}
            </div>
        );
    }
}
