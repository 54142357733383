import { OperationalContextClient } from "./controller/OperationalContextClient";

export class LocationsClient {
    private client: OperationalContextClient;

    constructor() {
        this.client = new OperationalContextClient();
    }

    getLocations() {
        return this.client.apiLocationsGet();
    }
}