import * as React from 'react';
import { TabularInformationCell, TabularInformationCellCommonProps } from './TabularInformationCell';
import { TextWithTooltip, TextWithTooltipProps } from '@its-suite/form-controls';

export interface TabularInformationValueProps extends TabularInformationCellCommonProps {
    contentText?: TextWithTooltipProps;
    contentJsx?: JSX.Element;
    className?: string;
}

export class TabularInformationValue extends React.Component<TabularInformationValueProps> {
    static defaultProps: Pick<TabularInformationValueProps, | 'contentText' | 'className'> = { contentText: { text: '-' }, className: '' };

    render(): JSX.Element {
        let content: JSX.Element = null;
        if (this.props.contentJsx) {
            content = this.props.contentJsx;
        }
        else {
            content = <TextWithTooltip {...this.props.contentText} />;
        }
        return (
            <TabularInformationCell className={this.props.className} cellMaxWidth={this.props.cellMaxWidth}>
                {content}
            </TabularInformationCell>);
    }
}
