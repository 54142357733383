// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabularInformation__fullWidth____3D5x{width:100%}.TabularInformation__tableRow___PgB6Y{display:table-row}.TabularInformation__tableCell___wvz27{display:table-cell;padding:2px 4px}.TabularInformation__tableCell___wvz27>p{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.TabularInformation__tableContainer___Tu6w6{margin-left:none}.TabularInformation__infoLabel___p7qfJ{color:rgba(255,255,255,.6)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "TabularInformation__fullWidth____3D5x",
	"tableRow": "TabularInformation__tableRow___PgB6Y",
	"tableCell": "TabularInformation__tableCell___wvz27",
	"tableContainer": "TabularInformation__tableContainer___Tu6w6",
	"infoLabel": "TabularInformation__infoLabel___p7qfJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
