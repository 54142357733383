import * as React from 'react';
import classNames from 'classnames';
import { FloatingWindow } from '../FloatingWindow';
import { DraggedElement } from './DraggedElement';
import { Context } from './DroppableWindow';

import styles from './DraggableWindow.scss';
import { Point } from '../../redux';

export interface DraggableWindowProps {
    title: string;
    id: string;
    className?: string;
    style?: React.CSSProperties;
    additionalIcons?: JSX.Element[];
    onClose(): void;
}

export class DraggableWindow extends React.Component<DraggableWindowProps> {
    static contextType = Context;
    context: React.ContextType<typeof Context>;
    
    private offset: Point = undefined;

    constructor(props: DraggableWindowProps) {
        super(props);

        this.onDrag = this.onDrag.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
    }

    render(): JSX.Element {
        let className: string = styles.draggable;
        if (this.props.className) {
            className = classNames(className, this.props.className);
        }
        return (
            <FloatingWindow
                id={this.props.id}
                title={this.props.title}
                onClose={this.props.onClose}
                className={className}
                style={this.props.style}
                draggableOptions={{ draggable: true, onDragStart: this.onDragStart, onDrag: this.onDrag }}
                additionalIcons={this.props.additionalIcons}
            >
                {this.props.children}
            </FloatingWindow>
        );
    }

    private onDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
        let draggedElement: DraggedElement = {
            id: this.props.id,
            offsetStart: {
                x: event.clientX - event.currentTarget.parentElement.offsetLeft,
                y: event.clientY - event.currentTarget.parentElement.offsetTop
            }
        };
        this.offset = draggedElement.offsetStart;
        event.dataTransfer.setData('text', JSON.stringify(draggedElement));
    }

    private onDrag = (event: React.DragEvent<HTMLDivElement>): void => {
        this.context.onDrag(event, this.props.id, this.offset);
    }
}
