import L, { LatLng } from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { connect } from "react-redux";
import { SynopticDto, TopologyDto } from "../../../../clients/controller/OperationalContextClient";
import { AppState } from "../../../../redux";
import StationIcon from '@material-ui/icons/LocationCity';

interface TopologyLayerProps {
    map: L.Map;
}

interface TopologyLayerStoreProps {
    topology: TopologyDto;
    filter: SynopticDto;
}

interface TopologyLayerState {
}

type TopologyLayerAllProps = TopologyLayerProps & TopologyLayerStoreProps;

class TopologyLayer extends React.Component<TopologyLayerAllProps, TopologyLayerState> {
    private markerList: L.Marker[] = [];
    constructor(props: TopologyLayerAllProps) {
        super(props);

        this.processSynoptic = this.processSynoptic.bind(this);
        this.addStationMarker = this.addStationMarker.bind(this);
    }

    private removeStations() {
        this.markerList.forEach(marker => marker.remove());
        this.markerList = [];
    }

    private drawStations() {
        if (this.props.filter.id !== -1) {
            this.processSynoptic(this.props.filter);
        } else {
            this.props.topology.sinopticos.map(synoptic => this.processSynoptic(synoptic));
        }
    }

    private processSynoptic(synoptic: SynopticDto) {
        let icon = L.divIcon({
            html: renderToStaticMarkup(<StationIcon fontSize="large" htmlColor={synoptic.col}/>),
            iconSize: [50, 50]
        });
        synoptic.estaciones.forEach(station => this.addStationMarker(station as unknown as number, icon));
    }

    private addStationMarker(stationId: number, icon: L.DivIcon) {
        let station = this.props.topology.estaciones.find(station => station.id === stationId);
        let marker = new L.Marker(new LatLng(station.lat, station.lon))
        .setIcon(icon).bindPopup(L.popup().setContent(`${station.nombre}`)).addTo(this.props.map);

        this.markerList.push(marker);
    }

    componentDidUpdate(prevProps: TopologyLayerAllProps): void {
        if (this.props.map === undefined) return;
        if (prevProps.topology.sinopticos !== this.props.topology.sinopticos ||
            prevProps.map !== this.props.map || prevProps.filter.id !== this.props.filter.id) {
            this.removeStations();
            this.drawStations();
        }
    }

    render(): JSX.Element {
        return (<></>);
    }
}

function mapStateToProps(state: AppState): TopologyLayerStoreProps {
    return ({
        topology: state.topology,
        filter: state.filterSelection
    });
}

export default connect<TopologyLayerStoreProps>(mapStateToProps)(TopologyLayer); 