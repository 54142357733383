import * as React from 'react';
import { Provider } from 'react-redux';
import { Point } from '../../redux';
import { DraggedElement } from './DraggedElement';

export interface DroppableWindowProps {
    id: string;
    className?: string;
    onDrop(id: string, position: Point): void;
}

export const Context = React.createContext<any>(undefined);

export class DroppableWindow extends React.Component<DroppableWindowProps> {

    constructor(props: DroppableWindowProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <div id={this.props.id} className={this.props.className} onDragOver={this.onDragOver} onDrop={this.onDrop}>
                <Context.Provider value={{ onDrag: this.onDrag}}>
                    {this.props.children}
                </Context.Provider>
            </div>
        );
    }

    private onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.stopPropagation();
        event.preventDefault();
    }

    private onDrop = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        let draggedElement: DraggedElement = JSON.parse(event.dataTransfer.getData('text'));
        let finalPoint: Point = {
            x: event.clientX - draggedElement.offsetStart.x,
            y: event.clientY - draggedElement.offsetStart.y
        };
        this.props.onDrop(draggedElement.id, finalPoint);
    }

    private onDrag = (event: React.DragEvent<HTMLDivElement>, id: string, offset: Point): void => {
        event.preventDefault();
        if (event.clientX === 0 && event.clientY === 0) return;
        let finalPoint: Point = {
            x: event.clientX - offset.x,
            y: event.clientY - offset.y
        };
        this.props.onDrop(id, finalPoint);
    }
}
