// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DetailList__container___h8GWm{position:fixed;height:calc(100% - 50px);width:100%;display:flex;flex-flow:column}.DetailList__filterCheck___vaSwr{height:fit-content;padding:10px;padding-left:20px}.DetailList__listContainer___jk565{flex-grow:1;overflow:auto;position:relative}.DetailList__circleIcon___DTl5w{padding-right:5px}.DetailList__fabButton___AokFu{position:fixed;bottom:20px;right:20px;z-index:1250}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floatingWindowPadding": "10px",
	"floatingWindowTitleHeight": "28px",
	"container": "DetailList__container___h8GWm",
	"filterCheck": "DetailList__filterCheck___vaSwr",
	"listContainer": "DetailList__listContainer___jk565",
	"circleIcon": "DetailList__circleIcon___DTl5w",
	"fabButton": "DetailList__fabButton___AokFu"
};
module.exports = ___CSS_LOADER_EXPORT___;
