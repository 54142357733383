import { TopologyDto } from '../../../clients/controller/OperationalContextClient';

export enum TopologyActionTypes {
    UPDATE_TOPOLOGY = 'UPDATE_TOPOLOGY',
    UPDATE_LINES = 'UPDATE_LINES',
    UPDATE_STATIONS = 'UPDATE_STATIONS',
}

export interface UpdateTopologyAction {
    type: TopologyActionTypes.UPDATE_TOPOLOGY;
    payload: TopologyDto;
}

export type TopologyAction = UpdateTopologyAction;

export const actionCreators = {
    updateTopology: (topology: TopologyDto): UpdateTopologyAction => ({
        type: TopologyActionTypes.UPDATE_TOPOLOGY,
        payload : topology
    }),
};