export class JwtStorage {
    key: string;
    renewKey: string;
    storage: Storage;

    constructor(storage = null) {
        this.key = 'JWT_TOKEN';
        this.renewKey = 'JWT_TOKEN_RENEW';
        this.storage = window.localStorage;
        this.storage = storage ? storage : window.localStorage;
    }
    saveToken(token: string) {
        this.set(this.key, token);
    }
    saveRenewToken(token: string) {
        this.set(this.renewKey, token);
    }
    readToken() {
        return this.get(this.key);
    }
    readRenewToken() {
        return this.get(this.renewKey);
    }
    removeToken() {
        this.remove(this.key);
        this.remove(this.renewKey);
    }
    removeRenewToken() {
        this.remove(this.renewKey);
    }
    private get(key: string) {
        return this.storage.getItem(key);
    }
    private set(key: string, value: string) {
        this.storage.setItem(key, value);
    }
    private remove(key: string) {
        this.storage.removeItem(key);
    }
}
