import { AppConfig } from "../../model";

export enum AppConfigurationActionTypes {
    SET_APP_CONFIGURATION = 'SET_APP_CONFIGURATION',
}

export interface SetConfigurationAction {
    type: AppConfigurationActionTypes;
    payload: AppConfig;
}

export type AppConfigurationAction = SetConfigurationAction;

// tslint:disable-next-line: typedef
export const actionCreators = {
    setConfiguration: (config: AppConfig): SetConfigurationAction => ({
        type: AppConfigurationActionTypes.SET_APP_CONFIGURATION,
        payload: config
    })
}