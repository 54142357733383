import { Reducer } from 'redux';
import { TopologyAction, TopologyActionTypes } from './actions';
import produce from 'immer';
import { TopologyDto } from '../../../clients/controller/OperationalContextClient';
export * from './actions';

const INITIAL_STATE: TopologyDto = {
    estaciones: [],
    sinopticos: []
};

export const reducer: Reducer<TopologyDto> = (state: TopologyDto = INITIAL_STATE, action: TopologyAction): TopologyDto => {
    switch (action.type) {
        case TopologyActionTypes.UPDATE_TOPOLOGY:
            return updateTopology(state, action.payload);
        default:
            return state;
    }
};

function updateTopology(state: TopologyDto, payload: TopologyDto): TopologyDto {
    return produce(state, draft => {
        draft.estaciones = payload.estaciones,
        draft.sinopticos = payload.sinopticos
    });
}


