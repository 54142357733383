import { Point } from '../../model';

export interface DraggableWindowsPositionDictionary {
    [key: string]: Point;
}

export interface DraggableWindowPositionPayload {
    id: string;
    position: Point;
}

export enum DraggableWindowPositionActionTypes {
    SET_DRAGGABLE_WINDOW_POSITION = 'SET_DRAGGABLE_WINDOW_POSITION',
}

export interface SetDraggableWindowPositionAction {
    type: DraggableWindowPositionActionTypes;
    payload: DraggableWindowPositionPayload;
}

export type DraggableWindowPositionAction = SetDraggableWindowPositionAction;

// tslint:disable-next-line: typedef
export const actionCreators = {
    setPosition: (id: string, position: Point): SetDraggableWindowPositionAction => ({
        type: DraggableWindowPositionActionTypes.SET_DRAGGABLE_WINDOW_POSITION,
        payload: { id: id, position: position }
    })
};
