import { ReducersMapObject } from 'redux';
import { ApplicationState, reducers as commonReducers } from '@its-suite/app-commons';

import {
    sessionReducers, organizationReducers, applicationReducers, SessionApplicationState, FrontendAppsApplicationState
} from '@its-suite/operational-context-commons';

import * as TopologyModule from './modules/topology';
import * as FilterSelectionModule from './modules/filterSelection';
import * as LayoutModule from './modules/layout';
import * as LinePanelSelectionModule from './modules/linePanelSelection';
import * as MapSelectionModule from './modules/mapSelection';
import * as WindowSelectorModule from './modules/windowSelector';
import * as AppConfigurationModule from './modules/appConfiguration';
import * as DraggableWindowPositionModule from './modules/draggableWindowPosition';
import * as VehicleStatusModule from './modules/vehicleStatus';
import * as MqttClientModule from './modules/mqttClient';
import * as LanguageSelectionModule from './modules/languageSelection';

import { AppConfig, VehicleStatusModel, LayoutModel, LanguageModel } from './model'
import { MqttClient } from '../clients/controller/MqttClient';
import { SynopticDto, TopologyDto } from '../clients/controller/OperationalContextClient';

export { LanguageSelectionModule, MqttClientModule, LayoutModule, VehicleStatusModule, FilterSelectionModule, LinePanelSelectionModule, MapSelectionModule, AppConfigurationModule, TopologyModule, WindowSelectorModule, DraggableWindowPositionModule };

export * from './model';
export * from './middleware';
export * from './sagas';
export * from './converters';

export interface AppState extends ApplicationState, SessionApplicationState, FrontendAppsApplicationState {
    layout: LayoutModel,
    mqttClient: MqttClient,
    topology: TopologyDto,
    vehicleStatus: VehicleStatusModel,
    filterSelection: SynopticDto
    configuration: AppConfig,
    language: LanguageModel,
    windowSelector: WindowSelectorModule.WindowSelectionDictionary,
    mapSelection: MapSelectionModule.MapSelectionDictionary;
    linePanelSelection: LinePanelSelectionModule.LinePanelSelectionDictionary;
    draggableWindowsPosition: DraggableWindowPositionModule.DraggableWindowsPositionDictionary;
}

export const AppReducers: ReducersMapObject<AppState> = {
    ...commonReducers,
    ...sessionReducers,
    ...applicationReducers,
    ...organizationReducers,
    layout: LayoutModule.reducer,
    mqttClient: MqttClientModule.reducer,
    topology: TopologyModule.reducer,
    vehicleStatus: VehicleStatusModule.reducer,
    filterSelection: FilterSelectionModule.reducer,
    configuration: AppConfigurationModule.reducer,
    language: LanguageSelectionModule.reducer,
    windowSelector: WindowSelectorModule.reducer,
    mapSelection: MapSelectionModule.reducer,
    linePanelSelection: LinePanelSelectionModule.reducer,
    draggableWindowsPosition: DraggableWindowPositionModule.reducer
};

