const labels = {
    es: {
        spanish: "Español",
        english: "Ingles",
        loginFormUser: "Usuario",
        loginFormPass: "Contraseña",
        loginFormTitle: "Introduzca sus credenciales",
        loginFormSubmit: "Acceder",
        loginError: "Error, compruebe las credenciales introducidas",

        optionMenuSettings: "Ajustes",
        optionMenuLanguage: "Idioma",
        optionMenuLogout: "Cerrar sesion",
        appBarOptions: "Mas opciones",
        appBarFilter: "Filtros",

        filterAllLines: "Todas las lineas",
        filterDiabled: "Sin filtro",

        trainDetailClosest: "Tren mas cercano",
        trainDetailNoTrainFound: "No se ha encontrado ningun tren",
        trainDetailFilter: "Filtro:",
        trainDetailFilterLong: "Filtro aplicado:",
        trainDetailNumber: "Nº de tren:",
        trainDetailLocation: "Última posicion:",
        trainDetailCoords: "Coordenadas:",
        trainDetailDistance: "Distancia:",
        trainDetailMaterial: "Material:",
    },
    en: {
        spanish: "Spanish",
        english: "English",
        loginFormUser: "User",
        loginFormPass: "Password",
        loginFormTitle: "Enter your credentials",
        loginFormSubmit: "Access",
        loginError: "Error, check submited credentials",

        optionMenuSettings: "Settings",
        optionMenuLanguage: "Language",
        optionMenuLogout: "Logout",
        appBarOptions: "More options",
        appBarFilter: "Filters",

        filterAllLines: "All lines",
        filterDiabled: "No filter",

        trainDetailClosest: "Closest train",
        trainDetailNoTrainFound: "No trains found",
        trainDetailFilter: "Filter: ",
        trainDetailFilterLong: "Applied filter:",
        trainDetailNumber: "Train number",
        trainDetailLocation: "Last position:",
        trainDetailCoords: "Coordinates:",
        trainDetailDistance: "Distance:",
        trainDetailMaterial: "Material:",
    },
  }
  
export default labels