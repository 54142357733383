import { Accordion, AccordionItem, CircleIcon, Switch } from "@its-suite/form-controls";
import React from "react";
import { connect } from "react-redux";
import { LocationsDto, SynopticDto } from "../../clients/controller/OperationalContextClient";
import { TrainDetail } from "../../components";
import { AppState, LanguageModel } from "../../redux";
import styles from './TrainDetail.scss';

export interface TrainDetailStoreProps {
    locations: LocationsDto[];
    filter: SynopticDto;
    language: LanguageModel;
}

export interface TrainDetailState {
    filterChecked: boolean;
}

type TrainDetailAllProps = TrainDetailStoreProps

class TrainDetailContainer extends React.Component<TrainDetailAllProps, TrainDetailState> {
    
    constructor(props: TrainDetailAllProps) {
        super(props);

        this.handleSwitchChange = this.handleSwitchChange.bind(this);

        this.state = {
            filterChecked: false
        }
    }

    componentDidUpdate(prevProps: Readonly<TrainDetailStoreProps>): void {
        if (this.props.filter === null) return;
        if (prevProps.filter.id !== this.props.filter.id) {
            this.setState(_ => ({ filterChecked: this.props.filter.id !== -1}))
        } 
    }

    render() {
        let closestVehicle: LocationsDto;
        this.props.locations.filter(location => this.state.filterChecked && this.props.filter.id !== -1 ? this.props.filter.estaciones.includes(location.lastId) : true)
            .forEach(location => {
                if (closestVehicle) {
                    if (location.distance < closestVehicle.distance) {
                        closestVehicle = {...location};
                    }
                } else {
                    closestVehicle = {...location};
                }
            });
        let title = (<Switch id='sw-default' checked={this.state.filterChecked} name={'sw-default'} label={this.state.filterChecked ? this.getFilterContent() : this.props.language["filterDiabled"]} onChange={this.handleSwitchChange} />);

        return (
            <>
                <div className={styles.switchContainer}>
                    {title}
                    <p className={styles.title}>{this.props.language["trainDetailClosest"]}</p>
                </div>
                <Accordion 
                    id={"trainList"}
                    accordionItems={closestVehicle ? [new TrainDetail(closestVehicle, this.props.language).getDetail()] : [this.getEmptyAccordion(title)]}
                    defaultExpanded={this.props.filter && this.props.filter.id !== -1}/>
            </>
        );
    }

    private handleSwitchChange(e: any): void {
        this.setState(state => ({filterChecked: !state.filterChecked}))
    }

    private getEmptyAccordion(check: JSX.Element): AccordionItem {
        return {
            key: "notFound",
            title: this.props.language["trainDetailNoTrainFound"],
            content: <></>,
        }
    }

    private getFilterContent(): string {
        let content = (
            <><CircleIcon className={styles.circleIcon} fillColor={this.props.filter.col} radius={7}/>{this.props.language["trainDetailFilter"]} {this.props.filter.nombre}</>
        );
        return content as unknown as string
    }
}

function mapStateToProps(state: AppState): TrainDetailStoreProps {
    return {
        locations: state.vehicleStatus.locations,
        filter: state.filterSelection,
        language: state.language,
    }
}

export default connect<TrainDetailStoreProps>(mapStateToProps)(TrainDetailContainer);