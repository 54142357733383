import { AppStatusModule } from '@its-suite/app-commons';
import { all, call, put, takeLatest, PutEffect, select } from 'redux-saga/effects';
import { TopologyModule, VehicleStatusModule } from '..';
import { LocationsDto, TopologyDto } from '../../clients/controller/OperationalContextClient';
import { JwtManager } from '../../clients/jwt';
import { LocationsClient } from '../../clients/LocationsClient';
import { LoginClient } from '../../clients/LoginClient';
import { TopologyClient } from '../../clients/TopologyClient';
import { AppActions } from "../actions";

export default class UpdateDataSaga {
    public static * watcher() {
        yield takeLatest(AppActions.ActionTypes.TRIGGER_UPDATE_DATA_SAGA, UpdateDataSaga.worker);
    }

    private static * worker(action: AppActions.TriggerUpdateDataSagaAction) {
        yield call(UpdateDataSaga.updateData);
    }

    private static * updateData() {
        console.log('AppSaga: UpdateData');

        let topologyClient: TopologyClient = new TopologyClient();
        let locationsClient: LocationsClient = new LocationsClient();

        const [vehicleStatus, topology]: [LocationsDto[], TopologyDto] =
            yield all([
                call([locationsClient, locationsClient.getLocations]),
                call([topologyClient, topologyClient.getTopology]),
            ]);

        let actionCreators: PutEffect[] = [
            put(TopologyModule.actionCreators.updateTopology(topology)),
            put(VehicleStatusModule.actionCreators.updateLocations(vehicleStatus)),
        ];

        yield all(actionCreators);
    }

    static * onErrorUpdatingSession() {
        console.error('RenewTokenSaga: Error updating session');
    }
}