import { WorkspaceLayoutDto } from "../../clients/controller/OperationalContextClient";
import { EstimationMultiplier } from "../model";
import { LayoutModel } from "../model/LayoutModel";
import { LinePanelSelectionModel } from "../model/LinePanelSelectionModel";
import { MapSelectionModel } from "../model/MapSelectionModel";

type PanelSelection = MapSelectionModel | LinePanelSelectionModel;
export type PanelTarget = 'map' | 'line-panel' | 'out-of-service-panel';
export type PanelAction = 'add' | 'update';

interface ModifySelectionPayload {
    selection: PanelSelection;
    target: PanelTarget;
    action: PanelAction;
}

export enum ActionTypes {
    TRIGGER_CONFIGURE_APP_SAGA = 'TRIGGER_CONFIGURE_APP',
    TRIGGER_RENEW_TOKEN_SAGA = 'TRIGGER_RENEW_TOKEN',
    TRIGGER_UPDATE_DATA_SAGA = 'TRIGGER_UPDATE_DATA',
    TRIGGER_LOGOUT_SAGA = 'TRIGGER_LOGOUT_SAGA', 
    UPDATE_ESTIMATION_MULTIPLIER = 'UPDATE_ESTIMATION_MULTIPLIER',

    CLEAR_SELECTIONS = 'RT_CLEAR_SELECTIONS',
    MODIFY_SELECTION = 'RT_MODIFY_SELECTION',

    HANDLE_CHANGE_ON_LAYOUT = 'RT_HANDLE_CHANGE_ON_LAYOUT',
    CREATE_WORKSPACE_LAYOUT = 'RT_CREATE_WORKSPACE_LAYOUT',

}

export interface HandleChangeOnLayoutAction {
    type: ActionTypes.HANDLE_CHANGE_ON_LAYOUT;
    payload: LayoutModel;
}

export interface ClearSelectionsAction {
    type: ActionTypes.CLEAR_SELECTIONS;
}

export interface CreateWorkspaceLayout {
    type: ActionTypes.CREATE_WORKSPACE_LAYOUT;
    payload: WorkspaceLayoutDto;
}

export interface ModifySelectionAction {
    type: ActionTypes.MODIFY_SELECTION;
    payload: ModifySelectionPayload;
}

export interface TriggerConfigureAppAction {
    type: ActionTypes.TRIGGER_CONFIGURE_APP_SAGA;
    payload: string;
}

export interface TriggerRenewTokenSagaAction {
    type: ActionTypes.TRIGGER_RENEW_TOKEN_SAGA;
}

export interface TriggerUpdateDataSagaAction {
    type: ActionTypes.TRIGGER_UPDATE_DATA_SAGA;
}

export interface TriggerLogoutSagaAction {
    type: ActionTypes.TRIGGER_LOGOUT_SAGA;
}

export interface UpdateEstimationAction {
    type: ActionTypes;
    payload: EstimationMultiplier;
}

export const actionCreators = {
    triggerConfigureApp: (configUri: string): TriggerConfigureAppAction => ({
        type: ActionTypes.TRIGGER_CONFIGURE_APP_SAGA,
        payload: configUri
    }),
    triggerRenewTokenSaga: (): TriggerRenewTokenSagaAction => ({
        type: ActionTypes.TRIGGER_RENEW_TOKEN_SAGA
    }),
    triggerUpdateDataSaga: (): TriggerUpdateDataSagaAction => ({
        type: ActionTypes.TRIGGER_UPDATE_DATA_SAGA
    }),
    triggerLogoutSaga: (): TriggerLogoutSagaAction => ({
        type: ActionTypes.TRIGGER_LOGOUT_SAGA
    }),
    clearSelections: (): ClearSelectionsAction => ({
        type: ActionTypes.CLEAR_SELECTIONS
    }),
    updateEstimation: (data: EstimationMultiplier): UpdateEstimationAction => ({
        type: ActionTypes.UPDATE_ESTIMATION_MULTIPLIER,
        payload: data
    }),
    handleChangeOnLayout: (layoutModel: LayoutModel): HandleChangeOnLayoutAction => ({
        type: ActionTypes.HANDLE_CHANGE_ON_LAYOUT,
        payload: layoutModel
    }),
    createWorkspaceLayout: (layout: WorkspaceLayoutDto): CreateWorkspaceLayout => ({
        type: ActionTypes.CREATE_WORKSPACE_LAYOUT,
        payload: layout
    }),
    modifySelection: (selection: PanelSelection, target: PanelTarget, action: PanelAction): ModifySelectionAction => ({
        type: ActionTypes.MODIFY_SELECTION,
        payload: {
            selection: selection,
            target: target,
            action: action
        }
    })
}