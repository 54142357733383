
export enum LanguageSelectionActionTypes {
    SET_LANGUAGE_SELECTION = 'SET_LANGUAGE_SELECTION',
}

export interface SetLanguageSelectionAction {
    type: LanguageSelectionActionTypes;
    payload: string;
}

export type LanguageSelectionActions = SetLanguageSelectionAction;

// tslint:disable-next-line: typedef
export const actionCreators = {
    updateSelection: (payload: string): SetLanguageSelectionAction => ({
        type: LanguageSelectionActionTypes.SET_LANGUAGE_SELECTION,
        payload: payload
    })
};