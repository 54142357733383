﻿"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const MoreHoriz_1 = require("@material-ui/icons/MoreHoriz");
const MenuItem_1 = require("@material-ui/core/MenuItem");
const ListItemIcon_1 = require("@material-ui/core/ListItemIcon");
const ListItemText_1 = require("@material-ui/core/ListItemText");
const DropdownContextMenu_1 = require("./DropdownContextMenu");
const classnames_1 = require("classnames");
const styles = require("./DropdownMenu.scss");
class MenuItemWithSubmenu extends React.Component {
    constructor(props) {
        super(props);
        this.render = () => {
            return (React.createElement(React.Fragment, null,
                React.createElement(MenuItem_1.default, { onClick: this.onButtonClick, className: styles.menuItem },
                    this.getIcon(),
                    React.createElement(ListItemText_1.default, null, this.props.text),
                    React.createElement(MoreHoriz_1.default, null)),
                React.createElement(DropdownContextMenu_1.DropdownContextMenu, { anchorEl: this.state.anchorEl, open: this.state.isMenuOpen, menuItems: this.props.suboptions, onClose: this.onClose, anchorOrigin: { vertical: 'top', horizontal: 'right' } })));
        };
        this.getIcon = () => {
            let result;
            if (this.props.icon) {
                result = React.createElement(ListItemIcon_1.default, { className: classnames_1.default(styles.icon, styles.menuItemIcon) }, this.props.icon);
            }
            else if (this.props.preserveLeftIconWidth) {
                result = React.createElement("div", { className: styles.icon });
            }
            return result;
        };
        this.onButtonClick = (e) => {
            this.setState({ isMenuOpen: true, anchorEl: e.currentTarget });
        };
        this.onClose = () => {
            this.setState({ isMenuOpen: false, anchorEl: null });
            this.props.onClose();
        };
        this.state = {
            isMenuOpen: false,
            anchorEl: null
        };
    }
}
exports.MenuItemWithSubmenu = MenuItemWithSubmenu;
