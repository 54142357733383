// This middleware will just add the property "async dispatch" to all actions

import { Action, Dispatch, MiddlewareAPI } from "redux";

export const asyncDispatchMiddleware = () => {
    return (storeAPI: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
        let syncActivityFinished = false;
        let actionQueue = [];
    
        function flushQueue() {
            actionQueue.forEach(a => storeAPI.dispatch(a)); // flush queue
            actionQueue = [];
        }
    
        function asyncDispatch(asyncAction) {
            actionQueue = actionQueue.concat([asyncAction]);
            if (syncActivityFinished) {
                flushQueue();
            }
        }
    
        const actionWithAsyncDispatch =
        Object.assign({}, action, { asyncDispatch });
    
        const res = next(actionWithAsyncDispatch);
    
        syncActivityFinished = true;
        flushQueue();
    
        return res;
    };
}