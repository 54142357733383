export class BrowserStorage {
    private storage: Storage;
    private readonly defaultLang = 'es';
    private readonly keys = {
        lang: 'LANG'
    };

    constructor(storage = null) {
        this.storage = window.localStorage;
        this.storage = storage ? storage : window.localStorage;
    }

    getLang() {
        let value = this.get(this.keys.lang);
        return value !== null ? value : this.defaultLang;
    }

    setLang(value: string) {
        this.set(this.keys.lang, value);
    }

    private get(key: string) {
        return this.storage.getItem(key);
    }

    private set(key: string, value: string) {
        this.storage.setItem(key, value);
    }

    private remove(key: string) {
        this.storage.removeItem(key);
    }
}
