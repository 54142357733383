import { TranslateFunction } from '@its-suite/app-commons';
import { CircleIcon, ClickAwayListener, IconButton, MenuItem, Tooltip } from '@its-suite/form-controls';
import MenuIcon from '@material-ui/icons/MoreVertRounded';
import FilterIcon from 'mdi-material-ui/Filter';
import * as React from 'react';
import { DropdownMenu } from '..';
import { SynopticDto } from '../../clients/controller/OperationalContextClient';
import { AppConfig, FilterSelectionModule, LanguageModel } from '../../redux';
import { ProductInfo } from '../ProductInfo';
import styles from './AppBar.scss';

export interface MenuOption {
    id?: number;
    text: string;
    color?: string;
    onClick: any;
}

export interface AppBarProps {
    appName: string;
    appVersion: string;
    menuOptions?: MenuOption[];
    config: AppConfig,
    filterOptions?: SynopticDto[];
    language: LanguageModel;
    translate: TranslateFunction;
    setFilterSelection(payload: SynopticDto): FilterSelectionModule.SetFilterSelectionAction; 
}

export interface AppBarState {
    sideMenuOpen: Boolean;
    filterMenuOpen: Boolean;
}

export class AppBar extends React.Component<AppBarProps, AppBarState> {
    dropDownMenu: React.RefObject<DropdownMenu>;
    constructor(props: AppBarProps) {
        super(props);
        
        this.onToggleSideMenu = this.onToggleSideMenu.bind(this);
        this.onCloseSideMenu = this.onCloseSideMenu.bind(this);
        this.onToggleFilterMenu = this.onToggleFilterMenu.bind(this);
        this.onCloseFilterMenu = this.onCloseFilterMenu.bind(this);

        this.dropDownMenu = React.createRef();

        this.state = {
            sideMenuOpen: false,
            filterMenuOpen: false
        };
    }

    onToggleSideMenu(): void {
        if (this.state.filterMenuOpen) this.onCloseFilterMenu();
        this.setState(state => ({ sideMenuOpen: !state.sideMenuOpen }));
    };

    onToggleFilterMenu(): void {
        if (this.state.sideMenuOpen) this.onCloseSideMenu();
        this.setState(state => ({ filterMenuOpen: !state.filterMenuOpen }));
    };

    onCloseSideMenu(): void {
        this.dropDownMenu.current.onClose();
    };

    onCloseFilterMenu(): void {
        if (this.state.filterMenuOpen) this.setState(_ => ({ filterMenuOpen: false }));
    };

    renderMenuButton(): JSX.Element {
        if (!this.props.menuOptions) return null;
        const items: MenuItem[] = [
            // {
            //     kind: 'WithMenu',
            //     key: 'lang',
            //     text: this.props.menuOptions[0].text,
            //     children: [
            //         {
            //             key: 'es',
            //             kind: 'Item',
            //             onClick: () => { this.props.menuOptions[0].onClick('es'); },
            //             text: this.props.language.spanish,
            //         },
            //         { kind: 'MenuDivider' },
            //         {
            //             key: 'en',
            //             kind: 'Item',
            //             onClick: () => { this.props.menuOptions[0].onClick('en'); },
            //             text: this.props.language.english,
            //         },
            //     ]
            // },
            {
                key: 'logout',
                kind: 'Item',
                onClick: this.props.menuOptions[1].onClick,
                text: this.props.menuOptions[1].text,
            }
        ];
        return(
                <IconButton onClick={undefined} className={styles.tabIcon}>
                    <DropdownMenu ref={this.dropDownMenu} className={styles.tabIcon} menuItems={items} buttonText={<MenuIcon fontSize='default'/> as unknown as string} />
                    {/* <MenuIcon fontSize='inherit'/> */}
                </IconButton>
        );
    };

    renderFilterButton(): JSX.Element {
        return (
                <IconButton onClick={this.onToggleFilterMenu} className={styles.tabIcon}>
                    <FilterIcon fontSize='inherit'/>
                </IconButton>
        );
    };

    renderItem(item: MenuOption): JSX.Element {
        return (
            <li key={item.text}>
                <button className={styles.sideMenuButton} onClick={(e: any) => this.itemClick(item.onClick)}>{item.text}</button>
            </li>
        );
    }

    itemClick(onClick: any) {
        onClick();
        this.onCloseSideMenu();
    }

    renderFilterMenu(): JSX.Element {
        if (!this.state.filterMenuOpen) return null;

        return (
            <div className={styles.sideMenuContainer}>
                <ul className={styles.sideMenuFilterList}>{this.renderFilterMenuItems()}</ul>
            </div>
        );
    }

    renderFilterMenuItems(): JSX.Element[] {
        let items = this.props.filterOptions.map(item => this.renderFilterItem(item));
        items.unshift(this.renderFilterItem({
            id: -1,
            nombre: this.props.language["filterAllLines"],
            col: '#FFFFFF',
            estaciones: []
        }));
        return items;
    }

    renderFilterItem(item: SynopticDto): JSX.Element {
        return (
            <li key={item.id}>
                <button className={styles.sideMenuButton} onClick={(e: any) => this.filterItemClick(item)}>
                    <CircleIcon className={styles.circleIcon} fillColor={item.col} radius={7}/>  {item.nombre}
                </button>
            </li>
        );
    }

    filterItemClick(item: SynopticDto) {
        this.props.setFilterSelection(item);
        this.onCloseFilterMenu();
    }

    renderProductInfo() {
        return(
            <div className={styles.appMenuContainer}>
                <ProductInfo productName={this.props.appName} navIconExtension={this.props.config.navIconExtension} productVersion={this.props.appVersion}/>
            </div>
        );
    };

    render() {
        console.log('AppBar render');
        return (
            <div>
                    <nav className={styles.appBar} style={{ width: '100%' }}>
                        <div className={styles.appMenuContainer}>{this.renderProductInfo()}</div>
                        <div className={styles.appMenuButton}>
                    <ClickAwayListener onClickAway={() => {this.onCloseFilterMenu();}}>
                            {this.renderFilterButton()}
            </ClickAwayListener>
                            {this.renderMenuButton()}
                        </div>
                    </nav>
                    {/* <div className={styles.appContent}>{this.renderMenu()}</div> */}
                    <div className={styles.appContent}>{this.renderFilterMenu()}</div>
                </div>
        );
    };
}