import { MapSelectionModule, LinePanelSelectionModule } from '..';
import { WorkspaceLayoutDto, WorkspaceLayoutUpdateDto } from '../../clients/controller/OperationalContextClient';
import { LayoutModel } from '../model/LayoutModel';

export class WorkspaceLayoutConverter {

    static fromModelToDto(
        name: string,
        isDefault: boolean,
        layout: LayoutModel,
        mapSelections: MapSelectionModule.MapSelectionDictionary,
        lineSelections: LinePanelSelectionModule.LinePanelSelectionDictionary,
    ): WorkspaceLayoutDto {
        return {
            ...WorkspaceLayoutConverter.fromModelToUpdateDto(layout, mapSelections, lineSelections),
            name: name,
            isDefault: isDefault
        };
    }

    static fromModelToUpdateDto(
        layout: LayoutModel,
        mapSelections: MapSelectionModule.MapSelectionDictionary,
        lineSelections: LinePanelSelectionModule.LinePanelSelectionDictionary,
    ): WorkspaceLayoutUpdateDto {
        return {
            layout: JSON.stringify(layout),
            panelSelections: JSON.stringify({ mapSelections: mapSelections, lineSelections: lineSelections })
        };
    }
}
