import { Reducer } from 'redux';
import { VehicleStatusActions, VehicleStatusActionTypes } from './actions';
import produce from 'immer';
import { VehicleStatusModel } from '../../model';
import { LocationsDto } from '../../../clients/controller/OperationalContextClient';
export * from './actions';

const INITIAL_STATE: VehicleStatusModel = {
    locations: [],
    closestVehicle: undefined
};

export const reducer: Reducer<VehicleStatusModel> = (state: VehicleStatusModel = INITIAL_STATE, action: VehicleStatusActions): VehicleStatusModel => {
    switch (action.type) {
        case VehicleStatusActionTypes.UPDATE_VEHICLE_STATUS:
            return updateVehicleStatus(state, action.payload);
        case VehicleStatusActionTypes.UPDATE_LOCATIONS:
            return updateLocations(state, action.payload);
        case VehicleStatusActionTypes.UPDATE_CLOSEST_VEHICLE:
            return updateClosestVehicle(state, action.payload);
        default:
            return state;
    }
};

function updateVehicleStatus(state: VehicleStatusModel, payload: VehicleStatusModel): VehicleStatusModel {
    return produce(state, draft => {
        draft.closestVehicle = payload.closestVehicle,
        draft.locations = payload.locations
    });
}

function updateLocations(state: VehicleStatusModel, payload: LocationsDto[]): VehicleStatusModel {
    return produce(state, draft => {
        draft.locations = payload
    });
}

function updateClosestVehicle(state: VehicleStatusModel, payload: LocationsDto): VehicleStatusModel {
    return produce(state, draft => {
        draft.closestVehicle = payload
    });
}


