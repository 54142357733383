import { WindowSelectionModel } from '../../model';

export interface WindowSelectionDictionary {
    [key: string]: string | string[];
}

export enum WindowSelectionTypes {
    SET_WINDOW_SELECTION = 'SET_WINDOW_SELECTION',
    CLEAR_WINDOW_SELECTION = 'CLEAR_WINDOW_SELECTION',
}

export interface SetWindowSelectionAction {
    type: WindowSelectionTypes.SET_WINDOW_SELECTION;
    payload: WindowSelectionModel;
}

export interface ClearWindowSelectionAction {
    type: WindowSelectionTypes.CLEAR_WINDOW_SELECTION;
    payload: WindowSelectionModel;
}

export type WindowSelectionAction = SetWindowSelectionAction | ClearWindowSelectionAction;

export const actionCreators = {
    setWindowSelection: (windowId: string, selectedAppId: string | string[]): SetWindowSelectionAction => ({
        type: WindowSelectionTypes.SET_WINDOW_SELECTION,
        payload : { windowId: windowId, selectedAppId: selectedAppId }
    }),
    clearWindowSelection: (windowId: string): ClearWindowSelectionAction => ({
        type: WindowSelectionTypes.CLEAR_WINDOW_SELECTION,
        payload: { windowId: windowId, selectedAppId: null }

    })
};
