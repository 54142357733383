// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App__contentContainer___GGt4_{background-color:#212121;position:absolute;left:0px;top:50px;bottom:0px;right:0px}.App__appBarContainer___Lkucc{padding-left:0px}.App__center-content___B7tAt{width:650px;display:flex;justify-content:space-between}.App__chartDemo___vXwKi{background:#9e9e9e}.App__appConfigContent___YphG1{height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floatingWindowPadding": "10px",
	"floatingWindowTitleHeight": "28px",
	"rtLateralMenuWidth": "0px",
	"contentContainer": "App__contentContainer___GGt4_",
	"appBarContainer": "App__appBarContainer___Lkucc",
	"centerContent": "App__center-content___B7tAt",
	"chartDemo": "App__chartDemo___vXwKi",
	"appConfigContent": "App__appConfigContent___YphG1"
};
module.exports = ___CSS_LOADER_EXPORT___;
