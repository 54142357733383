import { OperationalContextClient } from "./controller/OperationalContextClient";

export class TopologyClient {
    private client: OperationalContextClient;

    constructor() {
        this.client = new OperationalContextClient();
    }

    getTopology() {
        return this.client.apiTopologyGet();
    }
}