﻿"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s)  {
		if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) {
			t[p] = s[p];
		}
	}
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Menu_1 = require("@material-ui/core/Menu");
const MenuItem_1 = require("@material-ui/core/MenuItem");
const ListItem_1 = require("@material-ui/core/ListItem");
const ListItemIcon_1 = require("@material-ui/core/ListItemIcon");
const ListItemText_1 = require("@material-ui/core/ListItemText");
const Backdrop_1 = require("@material-ui/core/Backdrop");
const Divider_1 = require("@material-ui/core/Divider");
const styles = require("./ContextMenu.scss");
class ContextMenu extends React.Component {
    constructor() {
        super(...arguments);
        this.render = () => {
            let _a = this.props, { menuItems } = _a, rest = __rest(_a, ["menuItems"]);
            return (React.createElement("div", null,
                React.createElement(Backdrop_1.default, { open: this.props.open, onClick: this.handleClose, invisible: true, key: `contextmenu-backdrop` }),
                React.createElement(Menu_1.default, Object.assign({}, rest), this.renderMenuItems(this.props.menuItems, 0))));
        };
        this.renderMenuItems = (menuItems, indentLevel) => {
            return menuItems.map((menuItem, index) => this.renderMenuItem(menuItem, index, indentLevel));
        };
        this.renderMenuItem = (item, index, indentLevel) => {
            let element;
            if (item.kind === 'Item') {
                element = this.renderNestedMenuItem(item, index, indentLevel);
            }
            else if (item.kind === 'MenuDivider') {
                element = this.renderMenuDivider(item, index);
            }
            return element;
        };
        this.renderNestedMenuItem = (item, index, indentLevel) => {
            let content;
            if (this.hasChildren(item)) {
                content = (React.createElement(React.Fragment, null,
                    this.renderNestedMenuItemAsNotClickable(item, index, indentLevel),
                    this.renderMenuItems(item.children, indentLevel + 1)));
            }
            else {
                content = this.renderNestedMenuItemAsClickable(item, index, indentLevel);
            }
            return (React.createElement("div", { key: `${item.key}-item-${indentLevel}-${index}`, style: indentLevel > 0 ? { paddingLeft: styles.rtContextMenuIndentPadding } : {} }, content));
        };
        this.renderNestedMenuItemAsClickable = (item, index, indentLevel) => {
            let handleClick = (event) => {
                this.handleClose(event);
                item.onClick(event);
            };
            return (React.createElement(MenuItem_1.default, { key: `${item.key}-menuitem-${indentLevel}-${index}`, disabled: item.disabled, onClick: handleClick, onTouchStart: handleClick, onTouchEnd: this.preventOnClickIfOnTouchWasTriggered }, this.renderNestedMenuItemContent(item, index, indentLevel)));
        };
        this.renderNestedMenuItemAsNotClickable = (item, index, indentLevel) => {
            return (React.createElement(ListItem_1.default, { className: styles.MuiListItemInsideMenu, key: `${item.key}-menuitem-${indentLevel}-${index}` }, this.renderNestedMenuItemContent(item, index, indentLevel)));
        };
        this.renderNestedMenuItemContent = (item, index, indentLevel) => {
            return (React.createElement(React.Fragment, { key: `${item.key}-menuitemcontent-${indentLevel}-${index}` },
                item.icon && React.createElement(ListItemIcon_1.default, null, item.icon),
                React.createElement(ListItemText_1.default, null, item.text)));
        };
        this.renderMenuDivider = (item, index) => {
            return (React.createElement(Divider_1.default, { key: `${index}-divider` }));
        };
        this.handleClose = (event) => {
            event.stopPropagation();
            this.props.onClose();
        };
        /** Prevent firing onClick when onTouch was already fired (https://www.html5rocks.com/en/mobile/touchandmouse/#toc-together) */
        this.preventOnClickIfOnTouchWasTriggered = (event) => {
            event.preventDefault();
        };
        this.hasChildren = (item) => {
            var _a;
            return Boolean((_a = item.children) === null || _a === void 0 ? void 0 : _a.length);
        };
    }
}
exports.ContextMenu = ContextMenu;
