// This model is defined here because the library https://github.com/caplin/FlexLayout
// defines the whole model as any. Only needed properties have been defined
// Check library documentation for whole properties description

/** Tab component can be of types defined in: @type {TabComponentType}  */
export interface TabAttributes {
    type: 'tab';
    name: string;
    component: string;
    id?: string;
    enableClose?: boolean;
    enableDrag?: boolean;
    enableRename?: boolean;
    className?: string;
    enableRenderOnDemand?: boolean;
}

/** Tabset is a container of tabs */
export interface TabSetAttributes {
    type: 'tabset';
    weight: number;
    id?: string;
    selected?: number;
    children: TabAttributes[];
    classNameHeader?: string;
}

/** Row can contain another rows or tabsets
 *  Top level row will render horizontally, child 'rows' will render in the opposite orientation to their parent
 */
export interface RowAttributes {
    type: 'row';
    weight: number;
    children: Array<RowAttributes | TabSetAttributes>;
}

/** Not used yet */
export interface BorderAttributes {
    type: 'border';
}

/** Gloabal attibutes for the layout */
export interface GlobalConfigAttributes {
    splitterSize?: number;
    enableEdgeDock?: boolean;
    tabEnableRename?: boolean;
    tabSetEnableMaximize?: boolean;
    tabSetEnableDrag?: boolean;
}

/** Model used by the flex layout library */
export interface LayoutModel {
    global: GlobalConfigAttributes;
    borders: BorderAttributes[];
    layout: RowAttributes;
}

/** Allowed components to render inside a tab */
export enum TabComponentType {
    map = 'map',
    lineSynopticPanel = 'line synoptic panel',
    settingsPanel = 'settings panel'
}
