import { MessagesActions, SnackbarMessageModule } from '@its-suite/app-commons';
import { put, takeLatest } from 'redux-saga/effects';

export default class ErrorMessagesSaga {
    static *watcher() {
        yield takeLatest(MessagesActions.ActionTypes.SHOW_SNACKBAR_EXCEPTION_MESSAGE, ErrorMessagesSaga.showSnackbarExceptionMessage);
        yield takeLatest(MessagesActions.ActionTypes.SHOW_SNACKBAR_ERROR_MESSAGE, ErrorMessagesSaga.showSnackbarErrorMessage);
        yield takeLatest(MessagesActions.ActionTypes.SHOW_SNACKBAR_INFO_MESSAGE, ErrorMessagesSaga.showSnackbarInfoMessage);
    }
    static * showSnackbarExceptionMessage(action) {
        yield put(SnackbarMessageModule.actionCreators.show({ message: action.payload.message, isError: action.payload.isError }));
    }
    static * showSnackbarErrorMessage(action) {
        yield put(SnackbarMessageModule.actionCreators.show(action.payload));
    }
    static * showSnackbarInfoMessage(action) {
        yield put(SnackbarMessageModule.actionCreators.show(action.payload));
    }
}