import { applyMiddleware, createStore, combineReducers, Reducer, Store, ReducersMapObject, Action, StoreEnhancer, compose } from 'redux';
import * as ReactLocalizeRedux from 'react-localize-redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import { AppState, AppReducers, RenewTokenSaga, ConfigureAppSaga, LogoutSaga, SnackbarMessagesSaga, UpdateDataSaga, asyncDispatchMiddleware } from './redux';

let allReducers: Reducer<AppState>;

export default function configureStore(initialState?: AppState): Store<AppState> {
    const sagaMiddleware: SagaMiddleware = createSagaMiddleware();
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const enhancer: StoreEnhancer = composeEnhancers(applyMiddleware(sagaMiddleware, asyncDispatchMiddleware()));
    
    // Combine all reducers and instantiate the app-wide store instance
    allReducers = buildRootReducer(AppReducers);
    
    let store: Store<AppState> = createStore(rootReducer, initialState, enhancer);
    initializeSagaWatchers(sagaMiddleware);

    return store;
}

function initializeSagaWatchers(sagaMiddleware: SagaMiddleware): void {
    sagaMiddleware.run(ConfigureAppSaga.watcher);
    sagaMiddleware.run(SnackbarMessagesSaga.watcher);
    sagaMiddleware.run(RenewTokenSaga.watcher);
    sagaMiddleware.run(UpdateDataSaga.watcher);
    sagaMiddleware.run(LogoutSaga.watcher);
}

function buildRootReducer(reducerObjects: ReducersMapObject<AppState>): Reducer<AppState> {
    return combineReducers<AppState>({ ...reducerObjects, locale: ReactLocalizeRedux.localizeReducer });
}

const rootReducer: Reducer<AppState> = (state: AppState, action: Action): AppState => {
    return allReducers(state, action);
};
