import { OperationalContextClientConfiguration } from "@its-suite/operational-context-client";

export class BaseClient {
    getBaseUrl(unknown: string, baseUrl: string): string {
        return baseUrl || OperationalContextClientConfiguration.getBaseUrl();
    }
    transformOptions(options: RequestInit): Promise<RequestInit> {
        let newHeaders = new Headers(options.headers);
        let token = OperationalContextClientConfiguration.getToken();
        newHeaders.append('Authorization', `Bearer ${token}`);
        options.headers = newHeaders;
        return Promise.resolve(options);
    }
}
