import { LayoutModel } from '../../model';

export enum LayoutActionTypes {
    UpdateLayout = 'UPDATE_LAYOUT'
}

export interface UpdateLayoutAction {
    type: LayoutActionTypes.UpdateLayout;
    payload: LayoutModel;
}

export type Action = UpdateLayoutAction;

export const actionCreators = {
    updateLayout: (layout: LayoutModel): UpdateLayoutAction => ({
        type: LayoutActionTypes.UpdateLayout,
        payload: layout
    })
};
