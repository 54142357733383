import { SnackbarWrapper, TranslateFunction } from '@its-suite/app-commons';
import { createMuiTheme, MuiThemeProvider, Theme } from '@its-suite/form-controls';
import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { SynopticDto } from '../../clients/controller/OperationalContextClient';
import { AppBar } from '../../components/AppBar';
import { AppConfig, AppState, FilterSelectionModule, LanguageModel, LanguageSelectionModule } from '../../redux';
import { AppActions } from '../../redux/actions';
import { AppTheme } from '../../theme';
import { DetailListContainer } from '../DetailList';
import { MainArea } from '../MainArea';
import styles from './App.scss';

export enum RenderedWindow {
    MainArea = 'mainArea',
    DatailList = 'detailList',
    Settings = 'settings'
}

export interface AppProps {
    match: match<string>;
    translate: TranslateFunction;
}

interface AppStoreProps {
    synoptics: SynopticDto[];
    config: AppConfig;
    language: LanguageModel;
}

interface AppActionProps {
    setFilterSelection(payload: SynopticDto): FilterSelectionModule.SetFilterSelectionAction; 
    updateLangugaeSelection(payload: string): LanguageSelectionModule.SetLanguageSelectionAction; 
    logout(): AppActions.TriggerLogoutSagaAction;
}

export interface AppLocalState {
    window: RenderedWindow;
}

export type AppAllProps = AppProps & AppStoreProps & AppActionProps;

class App extends React.Component<AppAllProps, AppLocalState> {
    private theme: Theme = createMuiTheme(AppTheme.THEME_OPTIONS);

    constructor(props: AppAllProps) {
        super(props);

        this.onMainAreaClick = this.onMainAreaClick.bind(this);
        this.onDetailListClick = this.onDetailListClick.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onLanguageClick = this.onLanguageClick.bind(this);
        
        this.state = {
            window: RenderedWindow.MainArea
        }
    }

    render(): JSX.Element {
        console.log("App render");
        return (
            <SnackbarWrapper>
                <div className={styles.appBarContainer}>
                    <AppBar
                        appVersion={process.env.APP_VERSION}
                        appName={process.env.APP_NAME}
                        menuOptions={[
                            {onClick: this.onLanguageClick, text: this.props.language["optionMenuLanguage"]},
                            {onClick: this.props.logout, text: this.props.language["optionMenuLogout"]}
                        ]}
                        filterOptions={this.props.synoptics}
                        {...this.props}
                    />
                </div>
                <MuiThemeProvider theme={this.theme}>
                    <div id='contentContainer' className={styles.contentContainer}>
                        {this.renderContent()}
                    </div>
                </MuiThemeProvider>
            </SnackbarWrapper>
        );
    }

    private renderContent(): JSX.Element {
        switch (this.state.window) {
            case RenderedWindow.MainArea:
                return this.renderMainArea();
            case RenderedWindow.DatailList:
                return this.renderDetailList();
            case RenderedWindow.Settings:
                return this.renderSettings();
        }
    }

    private onMainAreaClick() {
        this.setState(_ => ({window: RenderedWindow.MainArea}));
    }
    
    private onDetailListClick() {
        this.setState(_ => ({window: RenderedWindow.DatailList}));
    }

    private onSettingsClick() {
        this.setState(_ => ({window: RenderedWindow.Settings}));
    }

    private onLanguageClick(lang: string) {
        this.props.updateLangugaeSelection(lang);
    }

    private renderMainArea = (): JSX.Element => {
        return (
            <React.Fragment>
                <MainArea onFabButtonClick={this.onDetailListClick}/>
            </React.Fragment>
        );
    }

    private renderDetailList = (): JSX.Element => {
        return (
            <React.Fragment>
                <DetailListContainer onFabButtonClick={this.onMainAreaClick}/>
            </React.Fragment>
        );
    }

    private renderSettings = (): JSX.Element => {
        return (
            <React.Fragment>
                <MainArea onFabButtonClick={this.onDetailListClick}/>
                {/* <SettingsContainer/> */}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        synoptics: state.topology.sinopticos,
        config: state.configuration,
        language: state.language,
        translate: state.translate,
    };
}

function mapDispatchToProps(dispatch: Dispatch): AppActionProps {
    return bindActionCreators({
        setFilterSelection: FilterSelectionModule.actionCreators.setSelection,
        updateLangugaeSelection: LanguageSelectionModule.actionCreators.updateSelection,
        logout: AppActions.actionCreators.triggerLogoutSaga
    }, dispatch);
}

export default connect<AppStoreProps, AppActionProps>(mapStateToProps, mapDispatchToProps)(App);
