import { Reducer } from 'redux';
import { Action, LayoutActionTypes } from './actions';
import { LayoutModel } from '../../model';
export * from './actions';

export const reducer: Reducer<LayoutModel> = (state: LayoutModel = null, action: Action): LayoutModel => {
    if (action.type === LayoutActionTypes.UpdateLayout) {
        return action.payload;
    }
    else {
        return state;
    }
};
