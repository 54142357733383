// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrainDetail__filterCheck___kvqr5{display:flex;flex-direction:row;padding:10px;padding-left:20px}.TrainDetail__circleIcon___cPKhf{padding-right:5px}.TrainDetail__switchContainer___Ud3zL{padding-left:20px}.TrainDetail__title___kRHK0{margin:0px;padding:0px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterCheck": "TrainDetail__filterCheck___kvqr5",
	"circleIcon": "TrainDetail__circleIcon___cPKhf",
	"switchContainer": "TrainDetail__switchContainer___Ud3zL",
	"title": "TrainDetail__title___kRHK0"
};
module.exports = ___CSS_LOADER_EXPORT___;
