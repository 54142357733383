import { AccordionItem } from "@its-suite/form-controls";
import AccessTimeIcon from '@material-ui/icons/AccessTimeRounded';
import LocationIcon from '@material-ui/icons/AddLocationRounded';
import TruckIcon from '@material-ui/icons/AirportShuttleRounded';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBusRounded';
import MovingIcon from '@material-ui/icons/TrendingUpRounded';
import React from "react";
import { LocationsDto } from "../../clients/controller/OperationalContextClient";
import { LanguageModel } from "../../redux";
import { StringUtils } from "../../utils/StringUtils";

export class TrainDetail {
    private location: LocationsDto;
    private title: JSX.Element;
    private language: LanguageModel;

    constructor(location: LocationsDto, language: LanguageModel, title?: JSX.Element) {
        this.location = location;
        this.title = title;
        this.language = language;
    }
    
    getDetail(): AccordionItem {
        let title = <>{this.title ? <>{this.title}<br/></> : <></>}<DirectionsBusIcon/> {this.language["trainDetailNumber"]} {this.location.nt}</>;
        let subtitle = <><AccessTimeIcon/> {this.language["trainDetailLocation"]} {StringUtils.getTime(this.location.timeStamp)}</>;
        let content = (
            <div style={{ width: '100%' }}>
                <hr color="#A9A9A9"/>
                <div style={{paddingLeft: 40}}>
                    <p><LocationIcon/> {this.language["trainDetailCoords"]} {this.location.lat}, {this.location.lon}</p>
                    <p><MovingIcon/> {this.language["trainDetailDistance"]} {this.location.distance ? this.getFormattedDistance() : ""}</p>
                    <p><TruckIcon/> {this.language["trainDetailMaterial"]} {this.location.mat}</p>
                </div>
            </div>
        );
        return {
            key: this.location.id,
            title: title as unknown as string,
            subtitle: subtitle as unknown as string,
            content: content
        };
    }

    getFormattedDistance(): string {
        if (this.location.distance > 1000)
            return (this.location.distance / 1000).toFixed(2) + "km";
        return this.location.distance.toFixed() + "m";
    }
}