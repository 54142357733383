import { SessionModule } from '@its-suite/operational-context-commons';
import { call, put, takeLatest } from 'redux-saga/effects';
import { JwtManager } from '../../clients/jwt';
import { LoginClient } from '../../clients/LoginClient';
import { AppActions } from "../actions";

export default class LogoutSaga {
    public static * watcher() {
        yield takeLatest(AppActions.ActionTypes.TRIGGER_LOGOUT_SAGA, LogoutSaga.worker);
    }
    private static * worker() {
        console.log('LogoutSaga');
        
        let loginClient = new LoginClient();
        yield call([loginClient, loginClient.logout])
        
        new JwtManager().forget();
        yield put(SessionModule.actionCreators.afterLogout());
        window.location.assign(`/`);
    }
}