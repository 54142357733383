import { PositionDto } from "../../../clients/controller/OperationalContextClient";
import { MapSelectionModel } from "../../model/MapSelectionModel";

export interface MapSelectionDictionary {
    [key: string]: MapSelectionModel;
}

export enum MapSelectionActionTypes {
    CreateMapSelection = 'CREATE_MAP_SELECTION',
    AddToMapSelection = 'ADD_TO_MAP_SELECTION',
    ChangeMapSelection = 'CHANGE_MAP_SELECTION',
    DeleteMapSelection = 'DELETE_MAP_SELECTION',
    TrackVehicleInMap = 'TRACK_VEHICLE_IN_MAP',
    StopTrackVehicleInMap = 'STOP_TRACK_VEHICLE_IN_MAP',
    CenterMapInVehicle = 'CENTER_MAP_IN_VEHICLE',
    ClearMapSelection = 'CLEAR_MAP_SELECTION'
}

export interface CreateMapSelectionAction {
    type: MapSelectionActionTypes.CreateMapSelection;
    payload: string;
}

export interface AddElementsToMapAction {
    type: MapSelectionActionTypes.AddToMapSelection;
    payload: MapSelectionModel;
}

export interface ChangeMapSelectionAction {
    type: MapSelectionActionTypes.ChangeMapSelection;
    payload: MapSelectionModel;
}

export interface DeleteMapSelectionAction {
    type: MapSelectionActionTypes.DeleteMapSelection;
    payload: string;
}

export interface TrackVehicleInMapAction {
    type: MapSelectionActionTypes.TrackVehicleInMap;
    payload: { mapId: string, vehicleAppId: string };
}

export interface StopTrackVehicleInMapAction {
    type: MapSelectionActionTypes.StopTrackVehicleInMap;
    payload: string;
}

export interface CenterMapInVehicleAction {
    type: MapSelectionActionTypes.CenterMapInVehicle;
    payload: { mapId: string, center: PositionDto, vehicleAppId: string };
}

export interface ClearMapSelectionAction {
    type: MapSelectionActionTypes.ClearMapSelection;
}

type TrackVehicleActions = CenterMapInVehicleAction | TrackVehicleInMapAction | StopTrackVehicleInMapAction;

export type Action = CreateMapSelectionAction | AddElementsToMapAction | ChangeMapSelectionAction | DeleteMapSelectionAction | TrackVehicleActions
    | ClearMapSelectionAction;

export const actionCreators = {
    createMapSelection: (mapId: string): CreateMapSelectionAction => ({
        type: MapSelectionActionTypes.CreateMapSelection,
        payload: mapId
    }),
    addElementsToMap: (selection: MapSelectionModel): AddElementsToMapAction => ({
        type: MapSelectionActionTypes.AddToMapSelection,
        payload: selection
    }),
    changeMapSelection: (selection: MapSelectionModel): ChangeMapSelectionAction => ({
        type: MapSelectionActionTypes.ChangeMapSelection,
        payload: selection
    }),
    deleteMapSelection: (mapId: string): DeleteMapSelectionAction => ({
        type: MapSelectionActionTypes.DeleteMapSelection,
        payload: mapId
    }),
    trackVehicleInMap: (mapId: string, vehicleAppId: string): TrackVehicleInMapAction => ({
        type: MapSelectionActionTypes.TrackVehicleInMap,
        payload: { mapId, vehicleAppId }
    }),
    stopTrackVehicleInMap: (vehicleId: string): StopTrackVehicleInMapAction => ({
        type: MapSelectionActionTypes.StopTrackVehicleInMap,
        payload: vehicleId
    }),
    centerMapInVehicle: (mapId: string, center: PositionDto, vehicleAppId: string): CenterMapInVehicleAction => ({
        type: MapSelectionActionTypes.CenterMapInVehicle,
        payload: { mapId, center, vehicleAppId }
    }),
    clearMapSelection: (): ClearMapSelectionAction => ({
        type: MapSelectionActionTypes.ClearMapSelection
    })
};
