import { Typography } from '@its-suite/form-controls';
import * as React from 'react';
import { MenuItem } from '../';
import { DropdownContextMenu } from './DropdownContextMenu';
import styles from './DropdownMenu.scss';

export interface DropdownMenuProps {
    buttonText: string;
    menuItems: MenuItem[];
    className?: string;
    preserveLeftIconWidth?: boolean;
}
interface DropdownMenuState {
    open: boolean;
    anchorEl: HTMLElement;
}
export class DropdownMenu extends React.Component<DropdownMenuProps, DropdownMenuState> {
    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
            anchorEl: null
        };
    }

    onButtonClick = (e) => {
        this.setState({ open: true, anchorEl: e.currentTarget });
    };
    
    onClose = () => {
        this.setState({ open: false, anchorEl: null });
    };

    render = () => {
        return (React.createElement("div", {},
            React.createElement("div", { className: styles.toggle, onClick: this.onButtonClick },
                React.createElement(Typography, { variant: 'body2' }, this.props.buttonText)),
            React.createElement(DropdownContextMenu, { anchorEl: this.state.anchorEl, open: this.state.open, menuItems: this.props.menuItems, onClose: this.onClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, preserveLeftIconWidth: this.props.preserveLeftIconWidth })));
    };
}
