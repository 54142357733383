import { MessagesActions } from "@its-suite/app-commons";
import { Accordion, AccordionItem } from "@its-suite/form-controls";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { LocationsDto, SynopticDto } from "../../clients/controller/OperationalContextClient";
import { TrainDetail } from "../../components";
import { AppState, LanguageModel, VehicleStatusModule } from "../../redux/";

export interface TrainDetalListProps {
    filter?: SynopticDto;
    language: LanguageModel;
}

export interface TrainDetalListStoreProps {
    locations: LocationsDto[];
}

export interface TrainDetalListActionProps {
    updateLocations(locations: LocationsDto[]): VehicleStatusModule.VehicleStatusActions;
    showSnackbar(message: string): MessagesActions.TriggerShowSnackbarErrorMessageAction;
}

export type TrainDetalListAllProps = TrainDetalListProps & TrainDetalListStoreProps & TrainDetalListActionProps;

class TrainDetailListContainer extends React.Component<TrainDetalListAllProps> {

    render(): JSX.Element {
        return(
            <div>
                <Accordion
                    id={"trainList"}
                    accordionItems={this.getItems()}
                    defaultExpanded={false}
                />
            </div>
        );
    }

    private getItems(): AccordionItem[] {
        let accordionItems: AccordionItem[] = [];
        this.props.locations.filter(location => this.props.filter && this.props.filter.id !== -1 ? this.props.filter.estaciones.includes(location.lastId) : true)
                            .forEach(location => accordionItems.push(new TrainDetail(location, this.props.language).getDetail()));
        return accordionItems;
    }
}

function mapStateToProps(state: AppState): TrainDetalListStoreProps {
    return {
        locations: state.vehicleStatus.locations,
    };
}

function mapDispatchToProps(dispatch: Dispatch): TrainDetalListActionProps {
    return bindActionCreators({
        updateLocations: VehicleStatusModule.actionCreators.updateLocations,
        showSnackbar: MessagesActions.actionCreators.triggerShowErrorMessage,
    }, dispatch);
}

export default connect<TrainDetalListStoreProps, TrainDetalListActionProps>(mapStateToProps, mapDispatchToProps)(TrainDetailListContainer);