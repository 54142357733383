// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloatingWindowTitle__title___x0IBI{font-size:30px;font-weight:500;color:rgba(255,255,255,.9);background:#424242;align-items:center;display:flex;justify-content:space-between;padding:5px 10px;position:absolute;left:0px;top:0px;right:0px;height:calc(28px - 10px)}.FloatingWindowTitle__iconContainer____y9xA{align-items:center;display:flex;justify-content:center;font-size:20px;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floatingWindowPadding": "10px",
	"floatingWindowTitleHeight": "28px",
	"title": "FloatingWindowTitle__title___x0IBI",
	"iconContainer": "FloatingWindowTitle__iconContainer____y9xA"
};
module.exports = ___CSS_LOADER_EXPORT___;
