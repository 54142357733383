import { BaseClient } from "./BaseClient";

export class OperationalContextClient extends BaseClient {
    private http: any;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined;

    constructor(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response>; }) {
        super();
        this.jsonParseReviver = undefined;
        this.http = http ? http : window;
        this.baseUrl = this.getBaseUrl("", baseUrl);
    }

    async apiLoginPost(username: string, password: string) {
        let url = this.baseUrl + "/auth/login";
        let body: string;
        if (username !== undefined && password !== undefined) {
            body = JSON.stringify({ user: username, password: password });
        }
        let options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: body
        };
        const response = await this.http.fetch(url, options);
        return this.processApiLoginPost(response);
    }

    protected async processApiLoginPost(response: Response) {
        const status = response.status;
        let headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v, k) => headers[k] = v);
        }
        ;
        if (status === 200) {
            const responseText = await response.text();
            let result200 = null;
            result200 = responseText === "" ? null : JSON.parse(responseText, this.jsonParseReviver);
            return result200;
        }
        else if (status !== 200 && status !== 204) {
            if (status == 403) return false;
            const responseText_1 = await response.text();
            return throwException("An unexpected server error occurred.", status, responseText_1, headers);
        }
        return Promise.resolve(null);
    }

    async apiLogoutGet() {
        let url = this.baseUrl + "/auth/logout";
        const transformedOptions = await this.transformOptions({});
        const response = await this.http.fetch(url, transformedOptions);
        return this.processApiLogoutGet(response);
    }

    protected async processApiLogoutGet(response: Response) {
        const status = response.status;
        if (status === 200) {
            return true;
        }
        else if (status !== 200 && status !== 204) {
            if (status == 403) return false;
            const responseText_1 = await response.text();
            return throwException("An unexpected server error occurred.", status, responseText_1, {});
        }
        return Promise.resolve(null);
    }

    async apiLoginRenewPost(refreshToken: string) {
        let url = this.baseUrl + "/auth/refresh_token";
        let options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json"
            },
            body: JSON.stringify({ refreshToken: refreshToken })
        };
        const transformedOptions = await this.transformOptions(options);
        const response = await this.http.fetch(url, transformedOptions);
        return await this.processApiLoginRenewPost(response);
    }

    protected async processApiLoginRenewPost(response: Response) {
        const status = response.status;
        let headers = {};
        if (response.headers && response.headers.forEach)
            response.headers.forEach((v, k) => headers[k] = v);

        if (status === 200) {
            const responseText = await response.text();
            let result200 = null;
            result200 = responseText === "" ? null : JSON.parse(responseText, this.jsonParseReviver);
            return result200;
        }
        else if (status !== 200 && status !== 204) {
            const responseText_1 = await response.text();
            return throwException("An unexpected server error occurred.", status, responseText_1, headers);
        }
        return Promise.resolve(null);
    }

    async apiTopologyGet(): Promise<TopologyDto> {
        let url = this.baseUrl + "/topology";
        url = url.replace(/[?&]$/, "");
        let options = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        const transformedOptions = await this.transformOptions(options);
        const response = await this.http.fetch(url, transformedOptions);
        return await this.processApiTopologyGet(response);
    }

    protected async processApiTopologyGet(response: Response): Promise<TopologyDto> {
        const status = response.status;
        let headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v, k) => (headers[k] = v));
        }
        if (status === 200) {
            const responseText = await response.text();
            let result200 = responseText === "" ? null : JSON.parse(responseText, this.jsonParseReviver);
            let topology: TopologyDto = result200;
            return topology;
        } else if (status !== 200 && status !== 204) {
            const responseText = await response.text();
            return throwException(
                "An unexpected server error occurred.",
                status,
                responseText,
                headers);
        }
        return Promise.resolve(null);
    }

    async apiLocationsGet(): Promise<LocationsDto[]> {
        let url = this.baseUrl + "/locations";
        url = url.replace(/[?&]$/, "");
        let options = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        const transformedOptions = await this.transformOptions(options);
        const response = await this.http.fetch(url, transformedOptions);
        return await this.processApiLocationsGet(response);
    }

    protected async processApiLocationsGet(response: Response): Promise<LocationsDto[]> {
        const status = response.status;
        let headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v, k) => (headers[k] = v));
        }
        if (status === 200) {
            const responseText = await response.text();
            let result200 = responseText === "" ? null : JSON.parse(responseText, this.jsonParseReviver);
            let locations: LocationsDto[] = result200;
            return locations;
        } else if (status !== 200 && status !== 204) {
            const responseText = await response.text();
            return throwException(
                "An unexpected server error occurred.",
                status,
                responseText,
                headers);
        }
        return Promise.resolve(null);
    }
}

class SwaggerException extends Error {
    private isSwaggerException: boolean;
    private status: number;
    private response;
    private headers;

    constructor(message, status, response, headers) {
        super();
        this.isSwaggerException = true;
        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
    }

    static isSwaggerException(obj: SwaggerException) {
        return obj.isSwaggerException === true;
    }
}

export interface LocationsDto {
    id: string;
    nt: string;
    timeStamp: string;
    mat: string;
    lat: number;
    lon: number;
    lastId: number;
    distance?: number;
}

export interface TopologyDto {
    estaciones: StationDto[];
    sinopticos: SynopticDto[];
}

export interface StationDto {
    id: number;
    nombre: string;
    cod: string;
    lat: number;
    lon: number;
}

export interface SynopticDto {
    id: number;
    nombre: string;
    col: string;
    estaciones: number[];
}

export interface StationListDto {
    id: number;
}

export enum AlertNotificationPriority {
    Low = "Low",
    Medium = "Medium",
    High = "High",
    VeryHigh = "VeryHigh",
    Critical = "Critical",
}

export interface PositionDto {
    latitude?: number | undefined;
    longitude?: number | undefined;
}

export interface WorkspaceLayoutDto {
    appId?: string | undefined;
    name: string;
    isDefault?: boolean | undefined;
    layout: string;
    panelSelections?: string | undefined;
}

export interface WorkspaceLayoutUpdateDto {
    layout: string;
    panelSelections?: string | undefined;
}

function throwException(message, status, response, headers, result?: any): any {
    if (result !== null && result !== undefined) throw result;
    else throw new SwaggerException(message, status, response, headers);
}
