import { LinePanelSelectionModel } from '../../model';

export interface LinePanelSelectionDictionary {
    [key: string]: LinePanelSelectionModel;
}

export enum LinePanelSelectionActionTypes {
    AddToLinePanelSelection = 'ADD_TO_LINE_PANEL_SELECTION',
    ChangeLinePanelSelection = 'CHANGE_LINE_PANEL_SELECTION',
    DeleteLinePanelSelection = 'DELETE_LINE_PANEL_SELECTION',
    ClearLinePanelSelection = 'CLEAR_LINE_PANEL_SELECTION',
}

export interface AddToLinePanelSelectionAction {
    type: LinePanelSelectionActionTypes.AddToLinePanelSelection;
    payload: LinePanelSelectionModel;
}

export interface UpdateLinePanelSelectionAction {
    type: LinePanelSelectionActionTypes.ChangeLinePanelSelection;
    payload: LinePanelSelectionModel;
}

export interface DeleteLinePanelSelectionAction {
    type: LinePanelSelectionActionTypes.DeleteLinePanelSelection;
    payload: string;
}

export interface ClearLinePanelSelectionAction {
    type: LinePanelSelectionActionTypes.ClearLinePanelSelection;
}

export type Action = AddToLinePanelSelectionAction | UpdateLinePanelSelectionAction | DeleteLinePanelSelectionAction
    | ClearLinePanelSelectionAction;

export const actionCreators = {
    addSelectionToPanel: (selection: LinePanelSelectionModel): AddToLinePanelSelectionAction => ({
        type: LinePanelSelectionActionTypes.AddToLinePanelSelection,
        payload: selection
    }),
    updatePanelSelection: (selection: LinePanelSelectionModel): UpdateLinePanelSelectionAction => ({
        type: LinePanelSelectionActionTypes.ChangeLinePanelSelection,
        payload: selection
    }),
    deletePanelSelection: (panelId: string): DeleteLinePanelSelectionAction => ({
        type: LinePanelSelectionActionTypes.DeleteLinePanelSelection,
        payload: panelId
    }),
    clearLinePanelSelection: (): ClearLinePanelSelectionAction => ({
        type: LinePanelSelectionActionTypes.ClearLinePanelSelection
    })
};
