import { AppStatusModule } from '@its-suite/app-commons';
import { FrontendAppDto, OperationalContextClientConfiguration } from '@its-suite/operational-context-client';
import { ApplicationsModule } from '@its-suite/operational-context-commons';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AppConfigurationModule, FilterSelectionModule } from '..';
import { JwtStorage } from '../../clients/jwt';
import { AppActions } from '../actions';
import { AppConfig } from '../model';
import { selectLanguage } from '../selectors/LanguageSelector';

export default class ConfigureAppSaga {
    public static * watcher() {
        yield takeLatest(AppActions.ActionTypes.TRIGGER_CONFIGURE_APP_SAGA, ConfigureAppSaga.worker);
    }

    private static * worker(action: AppActions.TriggerConfigureAppAction) {
        try {
            yield call(ConfigureAppSaga.loadApps);
            let appConfig: AppConfig = yield call(ConfigureAppSaga.getAppSpecificConfig);
            
            yield call(ConfigureAppSaga.setStaticLang);
            
            yield put(AppConfigurationModule.actionCreators.setConfiguration(appConfig));
            yield put(AppStatusModule.actionCreators.setAppStatus(AppStatusModule.AppStatusModel.Ready));
        }
        catch (error) {
            console.error(`Error configuring app: ${error}`);
            yield put(AppStatusModule.actionCreators.setAppStatus(AppStatusModule.AppStatusModel.Error));
        }
    }

    private static getAppSpecificConfig(): Promise<AppConfig> {
        console.log(`Get config`);
        
        return new Promise((resolve, reject) => {
            fetch("config.json")
                .then(response => response.json())
                .then((appConfig: AppConfig) => {
                    let operationalContextUrl: string = `${appConfig.BaseUrl}${appConfig.OperationalContextRelativeUrl}`;
                    OperationalContextClientConfiguration.init(operationalContextUrl, ConfigureAppSaga.readToken);
                    resolve(appConfig);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    private static * loadApps() {
        console.log(`ConfigureAppSaga set apps`);

        const apps: FrontendAppDto[] = [{ name: 'trenaurkitu', icon: 'icon' }];
        yield put(ApplicationsModule.actionCreators.setApplicationList(apps));
    }

    private static * setStaticLang() {
        console.log(`ConfigureAppSaga set lang`);
        let lang = yield select(selectLanguage);
        
        yield put(FilterSelectionModule.actionCreators.setSelection({
            id: -1,
            nombre: lang ? lang["filterAllLines"] : '',
            col: '#FFFFFF',
            estaciones: []
        }));
    }

    private static readToken(): string {
        return new JwtStorage().readToken();
    }
}