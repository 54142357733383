import { FabButton } from '@its-suite/form-controls';
import ListIcon from '@material-ui/icons/List';
import * as React from 'react';
import { MapContainer } from '../Map';
import { TrainDetailContainer } from '../TrainDetail';
import styles from './MainArea.scss';

interface MainAreaProps {
    onFabButtonClick(): void;
}

export class MainArea extends React.Component<MainAreaProps> {
    render = (): JSX.Element => {
        console.log('MainArea render');
        return (
            <div className={styles.mainArea}>
                <div className={styles.mapContainer}>
                    <MapContainer id={'test'}/>
                </div>
                <div className={styles.detailContainer}>
                    <FabButton className={styles.fabButton} onClick={this.props.onFabButtonClick}>
                        <ListIcon fontSize='large'/>
                    </FabButton>
                    <TrainDetailContainer />
                </div>
            </div>
        );
    }
}
