import { ApplicationRoutes, AppStatusModel, Locale } from '@its-suite/app-commons';
import { CircularProgress, createMuiTheme, defaultTheme, MuiThemeProvider, Paper, PickersUtilsProvider, Theme } from '@its-suite/form-controls';
import { ErrorPageInternalError, Logout } from "@its-suite/operational-context-commons";
import { StylesProvider } from "@material-ui/styles";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import { RealTimePermissions } from '../../authorization';
import AuthRoute from '../../authorization/AuthRoute/AuthRoute';
import { JwtManager } from '../../clients/jwt';
import { AppState, LanguageSelectionModule } from "../../redux";
import { AppActions } from "../../redux/actions";
import { App, AppProps } from '../App';
import { LoginContainer } from '../Login';
import styles from './Root.scss';


interface RootStoreProps {
    appStatus: AppStatusModel;
}

interface RootStoreActionProps {
    triggerConfigureAppSaga(configUri: string): AppActions.TriggerConfigureAppAction;
    triggerRenewTokenSaga(): AppActions.TriggerRenewTokenSagaAction;
    triggerUpdateDataSaga(): AppActions.TriggerUpdateDataSagaAction;
    updateLanguage(value: string): LanguageSelectionModule.LanguageSelectionActions;
}

type RootContainerAllProps = RootStoreProps & RootStoreActionProps;

class Root extends React.Component<RootContainerAllProps> {
    private readonly theme: Theme = createMuiTheme(defaultTheme);
    private readonly renewTokenTimeout: number = 55000; // 1 minute: 1 * 60 * 1000
    private readonly jwtManager: JwtManager = new JwtManager();
    private timerHandler: number = 0;

    componentDidMount = (): void => {
        this.props.triggerConfigureAppSaga(``);
        this.timerHandler = window.setInterval(this.renewTokenTimerElapsed, this.renewTokenTimeout);
    }

    componentWillUnmount = (): void => {
        if (this.timerHandler > 0) {
            window.clearInterval(this.timerHandler);
        }
        this.jwtManager.forget();
    }

    renewTokenTimerElapsed = (): void => {
        if (this.jwtManager.getRawToken()) {
            console.log('Root call to update session in store');
            this.props.triggerRenewTokenSaga();
            this.props.triggerUpdateDataSaga();
        }
    }

    render = (): JSX.Element => {
        console.log('Root render');
        const locale: string = Locale.get();
        
        let content: JSX.Element;
        if (this.props.appStatus === AppStatusModel.Loading) {
            content = this.renderLoading();
        }
        else {
            content = this.renderComponent();
        }

        return (
            <MuiThemeProvider theme={this.theme}>
                <PickersUtilsProvider locale={locale}>
                    <StylesProvider injectFirst={true}>
                        <Paper>
                            {content}
                        </Paper>
                    </StylesProvider>
                </PickersUtilsProvider>
            </MuiThemeProvider>
        );
    }

    renderContent = (props: AppProps): JSX.Element => {
        return this.renderApp(props);
    }

    renderLoading = (): JSX.Element => {
        return (
            <div className={styles.errorContainer}>
                <CircularProgress progressSize='fullSize' />
            </div>
        );
    }

    renderComponent = (): JSX.Element => {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path={ApplicationRoutes.LOGOUT} component={this.renderLogout} />
                    <Route exact={true} path={ApplicationRoutes.LOGIN} component={this.renderLogin} />
                    <AuthRoute component={this.renderContent} exact path={ApplicationRoutes.ROOT} permissions={RealTimePermissions.realtimeApp}/>
                </Switch>
            </HashRouter>
        );
    }

    renderLogin = (): JSX.Element => {
        console.log("Login render");

        return <LoginContainer />;
    }

    renderLogout = (): JSX.Element => {
        console.log("Logout render");

        return <Logout />;
    }

    renderError = (): JSX.Element => {
        return <ErrorPageInternalError />;
    }

    renderApp = (props: AppProps): JSX.Element => {
        console.log("App render"); 
        return (<App {...props} />);
    }
}

function mapStateToProps(state: AppState): RootStoreProps {
    return {
        appStatus: state.appStatus
    };
}

function mapDispatchToProps(dispatch: Dispatch): RootStoreActionProps {
    return bindActionCreators({
        triggerConfigureAppSaga: AppActions.actionCreators.triggerConfigureApp,
        triggerRenewTokenSaga: AppActions.actionCreators.triggerRenewTokenSaga,
        triggerUpdateDataSaga: AppActions.actionCreators.triggerUpdateDataSaga,
        updateLanguage: LanguageSelectionModule.actionCreators.updateSelection
    }, dispatch);
}

export const RootContainer: React.ComponentClass = connect<RootStoreProps, RootStoreActionProps>(mapStateToProps, mapDispatchToProps)(Root);
