import { Reducer } from 'redux';
import produce from 'immer';
import { LanguageSelectionActions, LanguageSelectionActionTypes } from './actions';
import { LanguageModel } from '../../model';
import labels from "../../../res/lang/labels"
import { BrowserStorage } from '../../../clients/BrowserStorage';
import { FilterSelectionModule } from '../..';
export * from './actions';

const INITIAL_STATE: LanguageModel = labels[new BrowserStorage().getLang()];

export const reducer: Reducer<LanguageModel> =
    (state: LanguageModel = INITIAL_STATE, action: LanguageSelectionActions): LanguageModel => {
        if (action.type === LanguageSelectionActionTypes.SET_LANGUAGE_SELECTION) {
            return updateSelection(state, action);
        }
        return state;
    };

function updateSelection(state: LanguageModel, action: any): LanguageModel {
    new BrowserStorage().setLang(action.payload);
    let lang: LanguageModel = labels[action.payload];
    let filter = FilterSelectionModule.INITIAL_STATE;
    filter.nombre = lang.filterAllLines;
    action.asyncDispatch(FilterSelectionModule.actionCreators.setSelection(filter));
    return lang;
}
