﻿"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) {
		if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) {
        t[p] = s[p];
		}
	}
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const __1 = require("../..");
const ContextMenu_1 = require("./ContextMenu");
class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.render = () => {
            let _a = this.props, { buttonTooltip, buttonContent, menuItems, disabled, unselectable } = _a, rest = __rest(_a, ["buttonTooltip", "buttonContent", "menuItems", "disabled", "unselectable"]);
            return (React.createElement("div", null,
                React.createElement(__1.Tooltip, { title: buttonTooltip },
                    React.createElement("div", null,
                        React.createElement(__1.IconButton, { onClick: this.onButtonClick, disabled: disabled }, buttonContent))),
                React.createElement(ContextMenu_1.ContextMenu, Object.assign({ anchorEl: this.state.anchorEl, open: this.state.open, menuItems: this.props.menuItems, onClose: this.onClose }, rest))));
        };
        this.onButtonClick = (e) => {
            this.setState({ open: true, anchorEl: e.currentTarget });
        };
        this.onClose = () => {
            this.setState({ open: false, anchorEl: null });
        };
        this.state = {
            open: false,
            anchorEl: null
        };
    }
}
exports.Menu = Menu;
