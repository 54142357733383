// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DropdownMenu__toggle___DsXOX{cursor:pointer;padding:5px;background-color:inherit;display:flex;justify-content:center;align-items:center}.DropdownMenu__menu-item___hFUfv{width:100%;display:flex;justify-content:space-between}.DropdownMenu__menu-item___hFUfv span{margin-right:15px}.DropdownMenu__icon___eSpNi{min-width:32px;width:32px}.DropdownMenu__menu-item-icon___bj70I{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": "DropdownMenu__toggle___DsXOX",
	"menuItem": "DropdownMenu__menu-item___hFUfv",
	"icon": "DropdownMenu__icon___eSpNi",
	"menuItemIcon": "DropdownMenu__menu-item-icon___bj70I"
};
module.exports = ___CSS_LOADER_EXPORT___;
