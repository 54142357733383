// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "::-webkit-scrollbar{width:.5em;height:.5em}::-webkit-scrollbar-thumb{background:#9e9e9e;border-radius:2px}::-webkit-scrollbar-track{background:#424242}::-webkit-scrollbar-corner{background:#424242}body{font-family:\"Roboto\",sans-serif;background:#212121;margin:0px}::selection{background:#80cbc4}::-moz-selection{background:#80cbc4}.Root__errorContainer___L2WWC{display:inline-grid;align-items:center;height:100vh;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContainer": "Root__errorContainer___L2WWC"
};
module.exports = ___CSS_LOADER_EXPORT___;
