import { Reducer } from 'redux';
import produce from 'immer';
import { DraggableWindowPositionAction, DraggableWindowPositionActionTypes, DraggableWindowPositionPayload, DraggableWindowsPositionDictionary } from './actions';
export * from './actions';

const INITIAL_STATE: DraggableWindowsPositionDictionary = {};

export const reducer: Reducer<DraggableWindowsPositionDictionary> =
    (state: DraggableWindowsPositionDictionary = INITIAL_STATE, action: DraggableWindowPositionAction): DraggableWindowsPositionDictionary => {
        if (action.type === DraggableWindowPositionActionTypes.SET_DRAGGABLE_WINDOW_POSITION) {
            return updatePosition(state, action.payload);
        }
        return state;
    };

function updatePosition(state: DraggableWindowsPositionDictionary, payload: DraggableWindowPositionPayload): DraggableWindowsPositionDictionary {
    return produce(state, draft => {
        draft[payload.id] = payload.position;
    });
}
