import { CircleIcon, FabButton, Switch } from "@its-suite/form-controls";
import React from "react";
import { connect } from "react-redux";
import { AppState, LanguageModel } from "../../redux";
import { TrainDetailListContainer } from "../TrainDetailList";
import styles from "./DetailList.scss";
import MapIcon from '@material-ui/icons/Map';
import { SynopticDto } from "../../clients/controller/OperationalContextClient";

export interface DetailListProps {
    onFabButtonClick(): void;
}

export interface DatailListStoreProps {
    filter: SynopticDto;
    language: LanguageModel;
}

export type DetailListAllProps = DetailListProps & DatailListStoreProps;

export interface DetailListState {
    filterChecked: boolean;
}

class DetailListContainer extends React.Component<DetailListAllProps, DetailListState> {
    
    constructor(props: DetailListAllProps) {
        super(props);

        this.handleSwitchChange = this.handleSwitchChange.bind(this);

        this.state = {
            filterChecked: false
        }
    }

    componentDidUpdate(prevProps: Readonly<DetailListAllProps>): void {
        if (prevProps.filter.id !== this.props.filter.id) {
            this.setState(_ => ({ filterChecked: this.props.filter.id !== -1}))
        } 
    }

    private handleSwitchChange(): void {
        this.setState(state => ({filterChecked: !state.filterChecked}))
    }

    private getFilterContent(): string {
        let content = (
            <div>
                <CircleIcon className={styles.circleIcon} fillColor={this.props.filter.col} radius={7}/>{this.props.language["trainDetailFilterLong"]} {this.props.filter.nombre}
            </div>
        );
        return content as unknown as string
    }

    render() {
        return(
            <div className={styles.container}>
                <div className={styles.filterCheck}>
                    <Switch id='sw-default' checked={this.state.filterChecked} name={'sw-default'} label={this.state.filterChecked ? this.getFilterContent() : this.props.language["filterDiabled"]} onChange={this.handleSwitchChange} />
                </div>
                <div className={styles.listContainer}>
                    <TrainDetailListContainer language={this.props.language} filter={this.state.filterChecked ? this.props.filter : undefined}/>
                </div>
                <FabButton className={styles.fabButton} onClick={this.props.onFabButtonClick}>
                        <MapIcon fontSize="large"/>
                </FabButton>
            </div>
        );
    }
}

function mapStateToProps(state: AppState): DatailListStoreProps {
    return {
        filter: state.filterSelection,
        language: state.language,
    };
}

export default connect<DatailListStoreProps>(mapStateToProps)(DetailListContainer);