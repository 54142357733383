import * as React from 'react';

import { TabularInformationLabel } from './TabularInformationLabel';
import { TabularInformationValue, TabularInformationValueProps } from './TabularInformationValue';

import styles from './TabularInformation.scss';
import classNames from 'classnames';

export interface TabularInformationItem extends TabularInformationValueProps {
    label: string;
    className?: string;
}

export interface TabularInformationRow {
    left: TabularInformationItem;
    right?: TabularInformationItem;
}

export interface TabularInformationProps {
    rows: TabularInformationRow[];
    /** Max width for label/value cells, overflow will be truncated with ellipsis */
    cellMaxWidth: string;
    tabularInformationClassName?: string;
}

export class TabularInformation extends React.Component<TabularInformationProps> {
    static defaultProps: Pick<TabularInformationProps, 'tabularInformationClassName'> = { tabularInformationClassName: '' };

    render(): JSX.Element {
        return (
            <div className={classNames(styles.fullWidth, this.props.tabularInformationClassName)}>
                {this.renderRows()}
            </div>
        );
    }

    private renderRows = (): JSX.Element[] => {
        let rows: JSX.Element[] = [];
        this.props.rows.forEach((row, i) => rows.push(this.getRow(row, i)));
        return rows;
    }

    private getRow = (row: TabularInformationRow, index: number): JSX.Element => {
        return (
            <div className={styles.tableRow} key={index}>
                {this.renderTabularInformationItem(row.left)}
                {row.right && this.renderTabularInformationItem(row.right)}
            </div>
        );
    }

    private renderTabularInformationItem = (item: TabularInformationItem): JSX.Element => {
        return (
            <React.Fragment>
                <TabularInformationLabel translatedText={item.label} {...this.props} />
                <TabularInformationValue {...item} {...this.props} />
            </React.Fragment>
        );
    }
}
