import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import configureStore from './configureStore';
import { AppState } from './redux';
import { RootContainer } from './containers/Root/Root';

import './assets/worker.js';

const initialState: AppState = (window as any).initialReduxState as AppState;
export const store: Store<AppState> = configureStore(initialState);

ReactDOM.render(
    <Provider store={store}>
        <RootContainer />
    </Provider>,
    document.getElementById('app')
);