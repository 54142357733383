import { Reducer } from 'redux';
import produce from 'immer';
import { FilterSelectionActions, FilterSelectionActionTypes } from './actions';
import { SynopticDto } from '../../../clients/controller/OperationalContextClient';
export * from './actions';

export const INITIAL_STATE: SynopticDto = {
    id: -1,
    nombre: "",
    col: '#FFFFFF',
    estaciones: []
};

export const reducer: Reducer<SynopticDto> =
    (state: SynopticDto = INITIAL_STATE, action: FilterSelectionActions): SynopticDto => {
        if (action.type === FilterSelectionActionTypes.SET_FILTER_SELECTION) {
            return updateSelection(state, action.payload);
        }
        return state;
    };

function updateSelection(state: SynopticDto, payload: SynopticDto): SynopticDto {
    return produce(state, draft => {
        draft.id = payload.id,
        draft.nombre = payload.nombre,
        draft.col = payload.col,
        draft.estaciones = payload.estaciones
    });
}
